import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createEndPoint, fetchWithToken } from '../../../services/util'
import { Analysis } from '../../../services/analysisService'
import {
  savePetAvatar,
  getPetAvatar,
  buyPetAvatar,
  updateAccumulatedCoins,
  buyPetAvatarGamificationEngine
} from '../../../services/rewardsService'
// import { Analysis } from '../../../services/analysisService'
import { getCourseGuid } from '../../../assets/data/api'
import { getCurrentLangString } from '../../../i18n/initI18n'
import { store } from '../../../store/configureStore'

function useMetaTabPets() {
  const userCurrentLevel = useSelector((s) => s.userExperience.level)
  const coinsAccumulated = useSelector((s) => s.rewards.coinsAccumulated)

  const [rewardsList, setRewardsList] = useState([])

  const [petAvatarList, setPetAvatarList] = useState([])
  const [petAvatarSelected, setPetAvatarSelected] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const [showBuyErrorPopup, setShowBuyErrorPopup] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const isFlagEnabledGamification =
    store.getState().configuration.isFlagEnabledGamification

  async function getPetAvatarsList() {
    const endPoint = isFlagEnabledGamification
      ? `game-engine/rewards-galleries/user/items/status`
      : `blueberry/rewards-galleries`
    // const endPoint = `blueberry/rewards-galleries/user/items`
    return fetchWithToken(createEndPoint(endPoint)).then((json) => json.data)
  }

  async function getUserRewards() {
    const courseGuid = await getCourseGuid()
    const endPoint = `blueberry/rewards-galleries/${courseGuid}/users`
    return fetchWithToken(createEndPoint(endPoint)).then((json) => json.data)
  }

  async function getData() {
    setIsLoading(true)

    // Full list of pets
    const apiPetsData = await getPetAvatarsList()
    // console.log('apiPetsData -- ', apiPetsData)
    // Change this line to use the API data
    setPetAvatarList(apiPetsData)

    if (!isFlagEnabledGamification) {
      const apiRewardsData = await getUserRewards()
      // console.log('apiRewardsData', apiRewardsData)

      // Purchased rewards
      // TODO: Filter by pets only
      // console.log('apiRewardsData', apiRewardsData)
      setRewardsList(apiRewardsData)
    }

    // Current pet avatar
    setPetAvatarSelected(getPetAvatar())
    setIsLoading(false)
  }

  const isRewardOwned = (guid) => {
    const foundReward = rewardsList.find(
      (reward) => reward.rewardGallery.guid === guid
    )
    return !!foundReward
  }

  // const getBuyRewardAnalyticsData = (guid) => {
  //   const foundReward = rewardsList.find(
  //     (reward) => reward.rewardGallery.guid === guid
  //   )

  //   const analyticsData = {
  //     pet_price: foundReward.rewardGallery.coins,
  //     pet_id: foundReward.rewardGallery.guid,
  //     coins_before_buying: foundReward.coins_before_buying,
  //     coins_after_buying:
  //       foundReward.coins_before_buying - foundReward.coins_redeemed
  //   }
  //   ocLog('getBuyRewardAnalyticsData', analyticsData)

  //   return analyticsData
  // }

  const translateDescription = (description) => {
    const langCode = getCurrentLangString()
    try {
      let descriptionObject
      // Verificar si la descripción es una cadena para parsearla.
      if (typeof description === 'string') {
        try {
          descriptionObject = JSON.parse(description)

          // En caso de error al parsear, se maneja la excepción.
          // Puedes decidir qué hacer en este caso, aqui simplemente se imprime un mensaje de error en la consola.
        } catch (error) {
          console.error('Error al parsear el string: ', error)
        }
      } else if (typeof description === 'object') {
        // Si es un objeto, no es necesario parsearlo.
        descriptionObject = description
      }
      return descriptionObject[langCode]
    } catch (error) {
      return description
    }
  }

  const sortedPetAvatarList = petAvatarList
    .map((item) => {
      if (isFlagEnabledGamification) {
        return {
          guid: item.id,
          animation: item.metadata ? JSON.parse(item.metadata) : null,
          petLevel: item.requiredLevel?.order,
          isLocked: item.requiredLevel?.order > userCurrentLevel,
          isOwned: item.owned,
          isExpensive: item.price > coinsAccumulated,
          isFree: item.price <= 0,
          price: item.price,
          name: item.name,
          description: translateDescription(item.description),
          singleImage: item.imageUrl,
          navigationIconImage: item.navigation_icon_image,
          card_image: item.card_image,
          card_image_svg: item.card_image_svg
        }
      } else {
        return {
          guid: item.guid,
          animation: item.animation,
          petLevel: item.reward_level,
          isLocked: item.reward_level > userCurrentLevel,
          isOwned: isRewardOwned(item.guid),
          isExpensive: item.coins > coinsAccumulated,
          isFree: item.isFree,
          price: item.coins,
          name: item.name,
          description: translateDescription(item.description),
          singleImage: item.single_image,
          navigationIconImage: item.navigation_icon_image,
          card_image: item.card_image,
          card_image_svg: item.card_image_svg
        }
      }
    })
    .sort((a, b) => {
      return a.price - b.price
    })
    .sort((a, b) => {
      return a.petLevel - b.petLevel
    })

  const handleSelectPetAvatar = (guid) => {
    const selectedPetAvatarData = petAvatarList.find((item) => {
      return isFlagEnabledGamification ? item.id === guid : item.guid === guid
    })

    let currentPetAvatar = {}

    if (guid === null) {
      currentPetAvatar = null

      Analysis.sendEvent(Analysis.EVENT.REMOVE_PET)

      Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['Pet Removed'])
    } else {
      if (isFlagEnabledGamification) {
        currentPetAvatar = {
          guid: selectedPetAvatarData.id,
          name: selectedPetAvatarData.name,
          animation: selectedPetAvatarData.metadata
            ? JSON.parse(selectedPetAvatarData.metadata)
            : null
        }
      } else {
        currentPetAvatar = {
          guid: selectedPetAvatarData.guid,
          name: selectedPetAvatarData.name,
          animation: selectedPetAvatarData.animation
        }
      }

      const currentState = store.getState()
      const petEventObject = {
        guid: currentPetAvatar.guid,
        name: currentPetAvatar.name,
        current_level: currentState.userExperience.level,
        current_points: currentState.rewards.coinsAccumulated
      }

      Analysis.sendEvent(Analysis.EVENT.SELECT_PET)

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Pet Popup Button Clicked'],
        petEventObject
      )
    }

    savePetAvatar(currentPetAvatar)
    setPetAvatarSelected(currentPetAvatar)

    // Show confirmation toast
    setShowToast(true)
    setTimeout(() => setShowToast(false), 3000)

    window.dispatchEvent(new CustomEvent('pet-avatar-change'))

    // Analysis.sendEvent(Analysis.EVENT.SELECT_PET, {
    //   pet_name: currentPetAvatar.name
    // })
  }

  const handleBuyPetAvatar = async (guid) => {
    let buyPetResult
    if (isFlagEnabledGamification) {
      buyPetResult = await buyPetAvatarGamificationEngine(guid)
    } else {
      buyPetResult = await buyPetAvatar(guid)
    }

    if (
      buyPetResult?.status === 'success' /* &&
      buyPetResult?.data.length  &&
      buyPetResult?.data[0]?.acquired === true */
    ) {
      // Si no tenemos avatar seleccionado, seleccionamos el que acabamos de comprar
      const currentPetAvatar = getPetAvatar()

      if (!currentPetAvatar || currentPetAvatar.guid !== guid)
        handleSelectPetAvatar(guid)

      getData()

      // Actualizar monedas acumuladas
      updateAccumulatedCoins()

      // Analysis.sendEvent(
      //   Analysis.EVENT.PET_PURCHASED,
      //   getBuyRewardAnalyticsData(guid)
      // )
    } else {
      setShowBuyErrorPopup(true)
      console.error('Error buyin pet', buyPetResult)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return {
    handleSelectPetAvatar,
    handleBuyPetAvatar,
    sortedPetAvatarList,
    petAvatarSelected,
    userCurrentLevel,
    coinsAccumulated,
    isLoading,
    showBuyErrorPopup,
    setShowBuyErrorPopup,
    showToast
  }
}

export default useMetaTabPets
