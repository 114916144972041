import React, { createContext, useReducer } from 'react'
import {
  CURRENCY_TYPES,
  GAMIFICATION_ACTIONS
} from '../../config/constants/GamificationConstants'

const initialState = {
  pointsReward: 0,
  currencyReward: {
    [CURRENCY_TYPES.COINS]: 0,
    [CURRENCY_TYPES.PIECES]: 0
  },
  levelUpInProgress: false,
  levelData: {},
  dailyGoalInProgress: false
}

const reducer = (state, action) => {
  const payload = action.payload

  switch (action.type) {
    case GAMIFICATION_ACTIONS.SET_POINTS_REWARDS:
      return {
        ...state,
        pointsReward: state.pointsReward + payload.points
      }

    case GAMIFICATION_ACTIONS.SET_CURRENCY_COIN_REWARDS:
      return {
        ...state,
        currencyReward: {
          ...state.currencyReward,
          [CURRENCY_TYPES.COINS]:
            state.currencyReward[CURRENCY_TYPES.COINS] + payload.coins
        }
      }

    case GAMIFICATION_ACTIONS.SET_CURRENCY_PIECES_REWARDS:
      return {
        ...state,
        currencyReward: {
          ...state.currencyReward,
          [CURRENCY_TYPES.PIECES]:
            state.currencyReward[CURRENCY_TYPES.PIECES] + payload.pieces
        }
      }

    case GAMIFICATION_ACTIONS.SET_LEVEL_UP_IN_PROGRESS:
      return {
        ...state,
        levelUpInProgress: payload.levelUpInProgress
      }

    case GAMIFICATION_ACTIONS.SET_LEVEL_DATA:
      return {
        ...state,
        levelData: payload.levelData
      }

    case GAMIFICATION_ACTIONS.RESET:
      return {
        ...state,
        pointsReward: 0,
        currencyReward: {
          [CURRENCY_TYPES.COINS]: 0,
          [CURRENCY_TYPES.PIECES]: 0
        }
      }

    case GAMIFICATION_ACTIONS.SET_DAILY_GOAL_IN_PROGRESS:
      return {
        ...state,
        dailyGoalInProgress: payload.dailyGoalInProgress
      }

    default:
      return state
  }
}

export const GamificationActivityContext = createContext()

export const GamificationActivityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  })

  return (
    <GamificationActivityContext.Provider value={{ state, dispatch }}>
      {children}
    </GamificationActivityContext.Provider>
  )
}
