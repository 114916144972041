import React from 'react'
import './GoBackButton.scss'

const GoBackButton = ({ onClick }) => {
  return (
    <button className="go-back-button" onClick={onClick} aria-label="Go back">
      <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAzMSAzMCI+PHJlY3Qgd2lkdGg9IjI4Ljc1IiBoZWlnaHQ9IjI4Ljc1IiB4PSIuNzExOTE0IiB5PSIuNjI1IiBmaWxsPSIjZmZmIiBmaWxsLW9wYWNpdHk9Ii4xIiByeD0iMTQuMzc1Ii8+PHJlY3Qgd2lkdGg9IjI4Ljc1IiBoZWlnaHQ9IjI4Ljc1IiB4PSIuNzExOTE0IiB5PSIuNjI1IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS4yNSIgcng9IjE0LjM3NSIvPjxwYXRoIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTE3LjExNzIgMTEuNDY5N2MuMjkyOS4yOTI5LjI5MjkuNzY3NyAwIDEuMDYwNkwxNC42NDc2IDE1bDIuNDY5NiAyLjQ2OTdjLjI5MjkuMjkyOS4yOTI5Ljc2NzcgMCAxLjA2MDYtLjI5MjguMjkyOS0uNzY3Ny4yOTI5LTEuMDYwNiAwbC0zLTNjLS4yOTI5LS4yOTI5LS4yOTI5LS43Njc3IDAtMS4wNjA2bDMtM2MuMjkyOS0uMjkyOS43Njc4LS4yOTI5IDEuMDYwNiAwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+" />
    </button>
  )
}

export default GoBackButton
