import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ReactComponent as SwitchIcon } from '../../../assets/img/metaberry_ui/switch_user.svg'
import { ReactComponent as BookIcon } from '../../../assets/img/metaberry_ui/book.svg'
import { ReactComponent as LogoutIcon } from '../../../assets/img/metaberry_ui/logout.svg'
import { ReactComponent as CrownIcon } from '../../../assets/img/metaberry_ui/crown.svg'
import { ReactComponent as AtIcon } from '../../../assets/img/metaberry_ui/at-symbol.svg'
import { ReactComponent as QuestionIcon } from '../../../assets/img/metaberry_ui/question.svg'
import { ReactComponent as GlobeIcon } from '../../../assets/img/metaberry_ui/globe.svg'
import { ReactComponent as Chevron } from '../../../assets/icons/chevron-down.svg'
import { ReactComponent as Check } from '../../../assets/icons/check_thin.svg'

import translate from '../../../i18n/translate'
import { useDispatch } from 'react-redux'
import { setIsGameUIVisible } from '../../../store/MetaberrySlice/MetaberrySlice'
import { getFamilyUserData } from '../../../services/userTypeService'
import {
  isSubscriptionHost,
  getLicenseInformation,
  CONTACT_EMAIL,
  FAQ_LINK
} from '../../../services/licenseService'
import { store } from '../../../store/configureStore'
import { Analysis } from '../../../services/analysisService'
import { setUserCustomLanguage } from '../../../services/userManagementService'
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup'

import './mapUserMenuModal.scss'

const MapUserMenuModal = ({ onClose, onLogoutClick }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isFamilyMember, setIsFamilyMember] = useState(false)
  const [isSubscriptionHostState, setIsSubscriptionHostState] = useState(false)
  const [isTrial, setIsTrial] = useState(false)
  const [subscriptionText, setSubscriptionText] = useState(
    translate('user_menu_subscription_manage')
  )
  const [daysLeft, setDaysLeft] = useState('0')

  const languages = [
    { name: translate('user_custom_language_default'), code: 'default' },
    { name: translate('user_custom_language_es'), code: 'es' },
    { name: translate('user_custom_language_en'), code: 'en' },
    { name: translate('user_custom_language_pt'), code: 'pt' }
  ]
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0])
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false)
  const [languageToChange, setLanguageToChange] = useState(languages[0])
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  useEffect(() => {
    setIsFamilyMember(getFamilyUserData() !== null)
    setSubscriptionData()

    let currentCustomLanguage =
      window.localStorage.getItem('bb_custom_language')
    currentCustomLanguage = currentCustomLanguage || 'default'

    for (const lang in languages) {
      if (languages[lang].code === currentCustomLanguage) {
        setSelectedLanguage(languages[lang])
        break
      }
    }
  }, [])

  const onMenuLogoutClick = () => {
    onClose()
    onLogoutClick()
  }

  const onMenuSwitchProfileClick = () => {
    onClose()
    dispatch(setIsGameUIVisible(false))

    Analysis.sendEvent(Analysis.EVENT.GO_TO_SWITCH_PLAYER)

    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Go To Change Player']
    )

    const logoutEvent = new CustomEvent('switch-player')
    window.dispatchEvent(logoutEvent)
    history.push('/change-profile')
  }

  const onMenuMyProfileClick = () => {
    window.dispatchEvent(new CustomEvent('close-dom-elements'))
    onClose()
    dispatch(setIsGameUIVisible(false))

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['Open My Profile'])

    history.push('/my-profile')
  }

  // trial - inactive

  const setSubscriptionData = async () => {
    const isSubscriptionHostAux = isSubscriptionHost()

    if (isSubscriptionHostAux) {
      const licenseInformation = await getLicenseInformation()
      const status = licenseInformation.status

      if (status) {
        setIsSubscriptionHostState(isSubscriptionHostAux)

        setDaysLeft(licenseInformation.leftDays || '?')

        const isTrialAux = status.includes('trial')
        setIsTrial(isTrialAux)

        if (!isTrialAux) {
          let plan = licenseInformation.stripe.plan_interval

          if (plan) {
            plan = plan.toLowerCase()
            let subscriptionTextAux =
              translate('user_menu_subscription_manage') + ' '

            if (plan.includes('year')) {
              subscriptionTextAux += translate(
                'user_menu_subscription_type_annual'
              )
            } else if (plan.includes('month')) {
              if (plan.includes('3')) {
                subscriptionTextAux += translate(
                  'user_menu_subscription_type_quarterly'
                )
              } else {
                subscriptionTextAux += translate(
                  'user_menu_subscription_type_monthly'
                )
              }
            }

            setSubscriptionText(subscriptionTextAux)
          }
        }
      }
    }
  }

  const goToSubscriptionPage = () => {
    Analysis.sendEvent(Analysis.EVENT.GO_TO_MANAGE_SUBSCRIPTION)

    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Manage Subscription']
    )

    const stripeLink = store.getState().subscription.customerPortalLink
    window.open(stripeLink, '_blank').focus()
  }

  const goToPricingPage = () => {
    const logoutEvent = new CustomEvent('exit-game-page')
    window.dispatchEvent(logoutEvent)
    setTimeout(() => {
      Analysis.sendEvent(Analysis.EVENT.GO_TO_SUBSCRIPTION_PLANS)

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Subscribe From Trial']
      )

      history.push('/pricing')
    }, 200)
  }

  const openSendMail = () => {
    Analysis.sendEvent(Analysis.EVENT.CONTACT_REQUEST)

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['Contact Clicked'])

    window.open('mailto:' + CONTACT_EMAIL, '_blank').focus()
  }

  const openFaqPage = () => {
    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['View FAQ'])

    window.open(FAQ_LINK, '_blank').focus()
  }

  const toggleLanguageDropdownOpen = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
  }

  const confirmLanguageChange = (userLanguage) => {
    setLanguageToChange(userLanguage)
    setIsPopupOpen(true)
  }

  const handleLanguageSelection = () => {
    setSelectedLanguage(languageToChange)
    setIsLanguageDropdownOpen(false)
    setUserCustomLanguage(languageToChange.code)
  }

  return (
    <>
      <div className="map-user-menu-modal__backdrop" onClick={onClose}></div>
      <div className="map-user-menu-modal">
        <div className="map-user-menu-modal__wrapper">
          {isSubscriptionHostState && isTrial && (
            <div className="map-user-menu-modal__trial-container">
              <div className="map-user-menu-modal__trial-title">
                {translate('user_menu_subscription_type_trial_title')}
              </div>
              <div className="map-user-menu-modal__trial-days-left">
                {translate('user_menu_subscription_type_trial_days_left', {
                  daysLeft: daysLeft
                })}
              </div>
              <div
                className="map-user-menu-modal__trial-button"
                onClick={goToPricingPage}
              >
                {translate('user_menu_subscription_type_trial_button')}
              </div>
            </div>
          )}

          {isFamilyMember && (
            <>
              <div
                className="map-user-menu-modal__action"
                onClick={onMenuSwitchProfileClick}
              >
                <SwitchIcon />
                <div className="map-user-menu-modal__action-text">
                  {translate('switch_player')}
                </div>
              </div>

              <div
                className="map-user-menu-modal__action"
                onClick={onMenuMyProfileClick}
              >
                <BookIcon />
                <div className="map-user-menu-modal__action-text">
                  {translate('my_profile')}
                </div>
              </div>
            </>
          )}

          <div className="map-user-menu-modal__action map-user-menu-modal__action-language">
            <GlobeIcon />
            <div className="map-user-menu-modal__action-dropdown">
              <div
                className="map-user-menu-modal__action-dropdown__selected-option map-user-menu-modal__action-text"
                onClick={toggleLanguageDropdownOpen}
              >
                <span>
                  {translate('user_custom_language_label') +
                    ': ' +
                    selectedLanguage.name}
                </span>
                <div
                  className={
                    isLanguageDropdownOpen
                      ? 'map-user-menu-modal__action-dropdown__chevron_inverse'
                      : ''
                  }
                >
                  <Chevron />
                </div>
              </div>

              {isLanguageDropdownOpen && (
                <div className="map-user-menu-modal__action-dropdown__options">
                  {languages.map((_language, index) => (
                    <div
                      key={index}
                      className="map-user-menu-modal__action-dropdown__option map-user-menu-modal__action-text"
                      onClick={() => confirmLanguageChange(_language)}
                    >
                      <span>{_language.name}</span>
                      {selectedLanguage.code === _language.code ? (
                        <Check />
                      ) : null}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {isSubscriptionHostState && (
            <>
              {!isTrial && (
                <div
                  className="map-user-menu-modal__action map-user-menu-modal__action-subscription"
                  onClick={goToSubscriptionPage}
                >
                  <CrownIcon />
                  <div className="map-user-menu-modal__action-text">
                    {subscriptionText}
                  </div>
                </div>
              )}

              <div className="map-user-menu-modal__separator"></div>

              <div
                className="map-user-menu-modal__action"
                onClick={openSendMail}
              >
                <AtIcon />
                <div className="map-user-menu-modal__action-text map-user-menu-modal__action-contact">
                  {CONTACT_EMAIL}
                </div>
              </div>

              <div
                className="map-user-menu-modal__action"
                onClick={openFaqPage}
              >
                <QuestionIcon />
                <div className="map-user-menu-modal__action-text">
                  {translate('user_menu_faq')}
                </div>
              </div>
            </>
          )}

          <div className="map-user-menu-modal__separator"></div>

          <div
            className="map-user-menu-modal__action"
            onClick={onMenuLogoutClick}
          >
            <LogoutIcon />
            <div
              className="map-user-menu-modal__action-text"
              data-testid="user_menu_exit_game"
            >
              {translate('user_menu_exit_game')}
            </div>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <ConfirmPopup
          size="sm"
          acceptButtonText={translate('user_custom_language_accept')}
          onAcceptButtonClick={handleLanguageSelection}
          onClose={() => setIsPopupOpen(false)}
        >
          <div className="custom-language-confirm-content">
            <div className="custom-language-confirm-title">
              {translate('user_custom_language_confirm_title', {
                language: languageToChange.name
              })}
            </div>

            <div className="custom-language-confirm-message">
              {translate('user_custom_language_confirm_message')}
            </div>
          </div>
        </ConfirmPopup>
      )}
    </>
  )
}

export default MapUserMenuModal
