import React, { useEffect, useState } from 'react'
import MetaberryView from '../../modules/views/MetaberryView/MetaberryView'
import './MetaBerryPage.scss'
import useMetaberryWithStore from '../../modules/views/MetaberryView/hooks/useMetaberryWithStore'
import { Analysis } from '../../services/analysisService'
import QuizLoading from '../TeacherPracticePage/components/QuizLoading/QuizLoading'
import { getUserAvatar } from '../../services/settingsService'
import LoadingPage from '../LoadingPage/LoadingPage'
import { useSelector } from 'react-redux'
import useInternetConnection from '../../hooks/useInternetConnection'
import InternetConnectionAlert from '../../modules/components/InternetConnectionAlert/InternetConnectionAlert'

const MetaberryPage = () => {
  const { availableUnits } = useMetaberryWithStore()
  // ocLog('--- Metaberry PAGE availableUnits', availableUnits)

  const [userAvatarImg, setUserAvatarImg] = useState(
    getUserAvatar().navigation_icon_image
  )

  const [hasLoaded, setHasLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [progressBarCounter, setProgressBarCounter] = useState(0)

  const initialPathSchool = useSelector(
    (state) => state.configuration.pathSchool
  )
  const [pathSchool, setPathSchool] = useState(initialPathSchool)
  const [isLoadingPathSchool, setIsLoadingPathSchool] = useState(true)

  const connectionStatus = useInternetConnection()
  const nameSchool = useSelector((state) => state.configuration.nameSchool)

  useEffect(() => {
    setIsLoadingPathSchool(true)
    handleLoadingStarted()
    // Temporal - para el cambio de urls de tener # a no tener y ser llamadas desde APPs
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ function: 'isNotPractice' })
      )
    }

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Map Page']
    )
    window.addEventListener('player-avatar-change', updateAvatar)
    window.addEventListener('loading-started', handleLoadingStarted)
    window.addEventListener('loading-finished', handleLoadingFinished)
    window.addEventListener('increase-progress-bar', handleProgressBar)
    window.addEventListener('game-started', changeInitialLoadStatus)

    return () => {
      window.removeEventListener('player-avatar-change', updateAvatar)
      window.removeEventListener('loading-started', handleLoadingStarted)
      window.removeEventListener('loading-finished', handleLoadingFinished)
      window.removeEventListener('increase-progress-bar', handleProgressBar)
      window.removeEventListener('game-started', changeInitialLoadStatus)
    }
  }, [])

  useEffect(() => {
    setPathSchool(initialPathSchool)
    const timeoutId = setTimeout(() => {
      setIsLoadingPathSchool(false)
    }, 500)

    // Función de limpieza para cancelar el timeout si el componente se desmonta
    return () => {
      clearTimeout(timeoutId)
    }
  }, [initialPathSchool])

  const changeInitialLoadStatus = () => {
    setHasLoaded(true)
  }

  const updateAvatar = () => {
    setUserAvatarImg(getUserAvatar().navigation_icon_image)
  }

  const handleLoadingStarted = () => {
    if (!isLoading) {
      setIsLoading(true)
    }
  }

  const handleLoadingFinished = () => {
    setIsLoading(false)
  }
  const handleProgressBar = (event) => {
    setProgressBarCounter(event.detail.count)
  }

  return (
    <div className="metaberry-page" data-testid="metaberry_page">
      <InternetConnectionAlert
        connectionStatus={connectionStatus}
        analysis={Analysis}
        school_name={nameSchool}
      />
      {isLoading && (
        <div className="metaberry-loading" data-testid="metaberry_loading">
          {hasLoaded ? (
            <QuizLoading
              message=""
              goFullScreen={() => {}}
              exitFullScreen={() => {}}
              isFullscreen={true}
              isPlayerJoinGameStarted={false}
              userAvatarImg={userAvatarImg}
            />
          ) : (
            <LoadingPage
              count={progressBarCounter}
              pathSchool={pathSchool}
              isLoadingPathSchool={isLoadingPathSchool}
            />
          )}
        </div>
      )}

      {availableUnits.length > 0 && <MetaberryView />}
    </div>
  )
}

export default MetaberryPage
