import React, { useEffect, useRef, useState } from 'react'
import { LemonadeRenderContext } from 'react-lemonade-editor'
import { ACTIVITY_TYPES } from '../../../constants/ActivityConstants'
import { useActivityContext } from '../../../context/ActivityContext/useActivityContext'
import { getDeviceType } from '../../../utils/devices'
import ExerciseAudioButton from '../ExerciseAudioButton/ExerciseAudioButton'
import './feedbackBox.scss'

const FeedbackBox = ({
  feedbackType,
  content,
  onActivityEvent,
  isVisible,

  /* Para la asistencia auditiva */
  panelInformationType,
  exerciseGuid,
  requiresAudioAssistance,
  getTextAudio,
  pathAssetsSchool
}) => {
  const { activityType } = useActivityContext()
  // Establecer tipo e icono para el panel de feedback
  // content = '<p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p>'
  const feedbackReference = useRef()

  const boxRef = useRef()
  const [variant] = useState(feedbackType.toLowerCase())
  const [audioText] = useState(content || '')
  const [isPhone, setIsPhone] = useState(false)

  const [blueberro_hint] = useState(`${pathAssetsSchool}/blueberro_hint.svg`)

  useEffect(() => {
    boxRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    setIsPhone(getDeviceType() === 'phone')
  }, [])

  return (
    <div className="feedback-box">
      <div className={'feedback-box__box type-' + variant} ref={boxRef}>
        {content && (
          <div className="feedback-box__content">
            <LemonadeRenderContext>
              <div
                ref={feedbackReference}
                className="lemonade-feedback-message"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </LemonadeRenderContext>

            {activityType !== ACTIVITY_TYPES.QUIZ && (
              <div className="feedback-box__content-audio">
                <ExerciseAudioButton
                  requiresSoundAssistance={requiresAudioAssistance}
                  exerciseGuid={exerciseGuid}
                  isVisible={true}
                  eventAudioType={panelInformationType}
                  getAudio={(signal) => getTextAudio(audioText, signal)}
                  onActivityEvent={onActivityEvent}
                />
              </div>
            )}
          </div>
        )}
        {!isPhone && (
          <div className="feedback-box__blueberro-hint">
            <img src={blueberro_hint} alt="Blueberro hint" />
          </div>
        )}
      </div>
    </div>
  )
}

export default FeedbackBox
