import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  courseUnitsImages: []
}

export const CourseSlice = createSlice({
  name: 'Course',
  initialState,
  reducers: {
    setCourse: (state, { payload }) => {
      state.data = payload
    },

    setCourseUnitsImages: (state, { payload }) => {
      state.courseUnitsImages = payload
    },

    resetCourse: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const { setCourse, setCourseUnitsImages, resetCourse } =
  CourseSlice.actions

export default CourseSlice.reducer
