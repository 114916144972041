import posthog from 'posthog-js'

export const FEATURE_FLAGS = {
  ENABLE_GAMIFICATION_ENGINE: 'enableGamificationEngine'
}

export default function isFeatureEnabled(flag) {
  // TODO habilitamos para todo el mundo el motor de gamificación por si los coles restringen las URL de posthog
  // Habría que quitar la comprobación de si esta feature flag esta habilitada en todo el código
  if (flag === FEATURE_FLAGS.ENABLE_GAMIFICATION_ENGINE)
    return (
      window.location.hostname.indexOf('layers') === -1 &&
      window.location.hostname.indexOf('family') === -1
    )
  return posthog.isFeatureEnabled(flag)
}
