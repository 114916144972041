import { React, useEffect, useState } from 'react'
import translate from '../../../i18n/translate'
import './mapSearchMenu.scss'
import magnifyIcon from '../../../assets/icons/icon_magnifying.svg'
import {
  ACTIVITY_TYPES,
  ActivityBlueberryComponent
} from '../../../_practice-components'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import Icon from '../../../assets/icons/arrows-expand.svg'
import useMapSearchMenu from './useMapSearchMenu'
import { getCurrentLangString } from '../../../i18n/initI18n'
import { getCourseMultiplicationSymbol } from '../../../services/courseInfoService'

const MapSearchMenuModal = ({ onCloseMenu, pathAssetsSchool }) => {
  const {
    handleInputChange,
    seedInputValue,
    previewActivity,
    errorMessage,
    visible,
    lemonadeData,
    sizeActivityClass,
    changeSizeJSONContainer,
    langId,
    getStimulusAudio,
    getTextAudio
  } = useMapSearchMenu()

  const [customMultiplicationSymbol, setCustomMultiplicationSymbol] =
    useState(undefined)

  useEffect(() => {
    setCustomMultiplicationSymbol(getCourseMultiplicationSymbol())
  }, [])

  return (
    <>
      <div className="game-menu-backdrop" onClick={onCloseMenu}></div>
      <div className="search-seed-option_container">
        <div className="search-seed-option_content">
          <input
            type="text"
            className="search-seed-option_input-search"
            placeholder={translate('search_label')}
            onChange={handleInputChange}
            value={seedInputValue}
          />
          <button
            className="search-seed-option_search-button"
            onClick={previewActivity}
          >
            <img
              className="search-seed-option_magnify-icon"
              src={magnifyIcon}
              alt="carga"
            ></img>
          </button>
        </div>
        <div className="search-seed-option_error-message">
          <div className="seed-error-message">{errorMessage}</div>
        </div>
      </div>

      {visible && lemonadeData && (
        <div
          className={`activity-popup-container single-activity-json__fullscreen--${sizeActivityClass}`}
        >
          <div className="search-seed-option_close-icon" onClick={onCloseMenu}>
            <CloseIcon />
          </div>
          <div
            className="single-activity-json__fullscreen-button"
            onClick={changeSizeJSONContainer}
          >
            <img src={Icon}></img>
          </div>
          <div className="search-seed-option_activity-popup-content">
            <ActivityBlueberryComponent
              activityType={ACTIVITY_TYPES.PREVIEW}
              activity={lemonadeData}
              onActivityEvent={() => {}}
              uiLanguage={getCurrentLangString()}
              isDemoMode={true}
              activityInformation={{}}
              hasCheckExerciseAPiResponded={true}
              requiresAudioAssistance={true}
              exerciseLanguage={langId}
              hasPreviowsPopups={false}
              getStimulusAudio={getStimulusAudio}
              getTextAudio={getTextAudio}
              mathRenderEngine="katex"
              multiplicationSymbol={customMultiplicationSymbol}
              pathAssetsSchool={pathAssetsSchool}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default MapSearchMenuModal
