import React from 'react'
import './mapScoreBar.scss'

import MapLevelBadge from '../MapLevelBadge/MapLevelBadge'
import MapDailyGoalProgressBar from '../MapDailyGoalProgressBar/MapDailyGoalProgressBar'
import MapItemsCounter from '../MapItemsCounter/MapItemsCounter'

const MapScoreBar = ({
  userExperience,
  dailyProgress,
  totalCoins = 0,
  totalPieces = 0,
  pathSchool,
  isFlagEnabledGamification
}) => {
  return (
    <div className="map-score-bar">
      <div className="map-score-bar__experience">
        <MapLevelBadge
          level={userExperience?.level}
          progress={userExperience?.progress}
          totalExperience={userExperience?.totalExperience}
          previousExperience={userExperience?.previousExperience}
        />
      </div>
      <div className="map-score-bar__daily-goal">
        <MapDailyGoalProgressBar
          progress={dailyProgress?.progress}
          totalExperience={dailyProgress?.totalExp}
          isFlagEnabledGamification={isFlagEnabledGamification}
        />
      </div>
      <div className="map-score-bar__gears">
        <MapItemsCounter variant="gear" count={totalPieces} />
      </div>
      <div className="map-score-bar__coins">
        <MapItemsCounter
          variant="coin"
          count={totalCoins}
          arrowPosition={'right'}
          pathSchool={pathSchool}
        />
      </div>
    </div>
  )
}

export default MapScoreBar
