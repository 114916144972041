import React, { useRef, useState, useEffect } from 'react'
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import 'swiper/swiper-bundle.css'
import './AvatarSlider.scss'
import AvatarCardSlider from '../AvatarCardSlider/AvatarCardSlider'
import ConfirmPopupOptions from '../../../ConfirmPopupOptions/ConfirmPopupOptions'
import CardDetails from '../../../MetaTabCommons/CardDetails/CardDetails'
import translate from '../../../../../i18n/translate'

const AvatarSlider = ({
  avatars,
  avatarSelected,
  playSoundEffect,
  handleSelectAvatar,
  setDataAvatarPending,
  startSlide,
  isIntro,
  cardButtonText,
  isSelected,
  handleMainButton
}) => {
  const swiperRef = useRef(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [avatarSwiperSelected, setAvatarSwiperSelected] = useState(null)
  const [popupButtonText, setPopupButtonText] = useState('')
  const [isPopupButtonDisabled, setIsPopupButtonDisabled] = useState(false)

  const handleOpenPopup = () => setIsPopupOpen(true)
  const handleClosePopup = () => setIsPopupOpen(false)

  const showCardDetail = (isSelected, avatar) => {
    setPopupButtonText(
      translate(isSelected ? 'avatar_selected' : 'avatar_confirm')
    )
    setIsPopupButtonDisabled(isSelected)
    handleOpenPopup()
  }

  const onAcceptButtonClick = () => {
    playSoundEffect('avatarSelected')
    handleSelectAvatar(avatarSwiperSelected)
    handleClosePopup()
  }

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.realIndex !== startSlide) {
      const index = startSlide >= 0 ? startSlide : 0
      swiperRef.current.slideToLoop(index, 0, false)
    }
  }, [startSlide])

  const handleSlideChange = () => {
    if (swiperRef.current) {
      const newRealIndex = swiperRef.current.realIndex
      setDataAvatarPending(avatars[newRealIndex])
      setAvatarSwiperSelected(avatars[newRealIndex])
    }
  }

  return avatars && avatars.length ? (
    <>
      <div className="avatar_slider__container">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
          spaceBetween={-30}
          slidesPerView={avatars.length < 5 ? 3 : 5}
          centeredSlides={true}
          centeredSlidesBounds={true}
          observer={true}
          observeParents={true}
          navigation
          onSwiper={(swiper) => {
            swiperRef.current = swiper
          }}
          initialSlide={startSlide}
          onSlideChange={handleSlideChange}
          loop={true}
          className={isIntro ? 'avatar-swiper-intro' : 'avatar-swiper'}
        >
          {avatars.map((avatar, index) => (
            <SwiperSlide key={avatar.guid}>
              <div
                className={`meta-popup-selector__grid-item ${
                  avatarSelected.single_image === avatar.single_image
                    ? 'selected-slider'
                    : 'no-selected-slider'
                }`}
              >
                <AvatarCardSlider
                  isSelected={
                    avatarSelected.single_image === avatar.single_image
                  }
                  avatarImage={avatar.single_image}
                  avatarName={avatar.name}
                  avatarDescription={avatar.description}
                  onClickSelect={() => {
                    playSoundEffect('slider')
                    handleSelectAvatar(avatar)
                  }}
                  isIntro={isIntro}
                  index={index}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {!isIntro && cardButtonText && (
        <div className="avatar_slider__button-container">
          <div
            className={`avatar_slider__button--${
              isSelected ? 'default' : 'available'
            }`}
            onClick={() => {
              const currentAvatar = avatars[swiperRef.current?.realIndex]
              showCardDetail(
                avatarSelected.single_image === currentAvatar?.single_image,
                currentAvatar
              )
            }}
          >
            {cardButtonText}
          </div>
        </div>
      )}
      {isPopupOpen && (
        <ConfirmPopupOptions
          aditionalClass="card-preview"
          size="xs"
          acceptButtonText={popupButtonText}
          onAcceptButtonClick={onAcceptButtonClick}
          isAcceptButtonDisabled={isPopupButtonDisabled}
          onClose={handleClosePopup}
          showBtnCancel={false}
        >
          <CardDetails
            name={avatars[swiperRef.current?.realIndex]?.name}
            description={avatars[swiperRef.current?.realIndex]?.description}
            image={avatars[swiperRef.current?.realIndex]?.card_image}
            isSelected={
              avatarSelected.single_image ===
              avatars[swiperRef.current?.realIndex]?.single_image
            }
          />
        </ConfirmPopupOptions>
      )}
    </>
  ) : null
}

export default AvatarSlider
