import { createEndPoint, fetchWithToken, fetcher, getToken } from './util'
import { store } from '../store/configureStore'
import { setAvatarsNotification as setAvatarsNotificationRedux } from '../store/RewardsSlice/RewardsSlice'

export async function getAvatars() {
  const endPoint = `blueberry/avatars`
  return fetchWithToken(createEndPoint(endPoint)).then((json) => json.data)
}

export function saveAvatar(
  avatarAnimationUrL,
  avatarSingleUrl,
  navigationIconImage,
  spriteJson
) {
  let parsedSpriteJson = {}
  try {
    parsedSpriteJson = JSON.parse(spriteJson)
  } catch (e) {}

  const settingAvatar = {
    blueberry: {
      avatar: {
        url: avatarAnimationUrL,
        single_image: avatarSingleUrl,
        navigation_icon_image: navigationIconImage,
        sprite_json: parsedSpriteJson
      }
    }
  }
  const endPoint = `users/settings`
  fetcher(createEndPoint(endPoint), {
    method: 'PUT',
    body: JSON.stringify(settingAvatar),
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    }
  })
    .then((json) => json.data)
    .catch((error) => console.error('saveAvatar error', error))

  const settings = JSON.parse(window.localStorage.getItem('bb_settings'))
  settings.blueberry.avatar.url = avatarAnimationUrL
  settings.blueberry.avatar.single_image = avatarSingleUrl
  settings.blueberry.avatar.navigation_icon_image = navigationIconImage
  settings.blueberry.avatar.sprite_json = parsedSpriteJson

  window.localStorage.setItem('bb_settings', JSON.stringify(settings))
}

export function setAvatarsNotification(notificationValue) {
  store.dispatch(setAvatarsNotificationRedux(notificationValue))

  // ocLog('SETTING PETS NOTIFICATION', notificationValue)
}
