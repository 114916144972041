const MINIMUN_SIZE_IN_PIXELS = 550

export const userDeviceInformation = () => {
  const info = window.navigator
  const usefulInfo = {}
  usefulInfo.userAgent = info?.userAgent
  usefulInfo.connectionType = info?.connection?.effectiveType
  usefulInfo.connectionRtt = info?.connection?.rtt
  usefulInfo.language = info?.language
  usefulInfo.languages = info?.languages && info?.languages.join(', ')
  usefulInfo.maxTouchPoints = info?.maxTouchPoints
  usefulInfo.platform =
    info?.userAgentData?.platform || navigator?.platform || 'unknown'
  usefulInfo.product = info?.product
  usefulInfo.userAgent = info?.userAgent
  usefulInfo.userAgentData = info?.userAgentData
  usefulInfo.vendor = info?.vendor

  return usefulInfo
}

export const isLargerThanMinimunSize = (
  minimunSize = MINIMUN_SIZE_IN_PIXELS
) => {
  const screenWidth = window.innerWidth // SIN devicePixelRatio es espacio para "dibujar", CON son pixeles reales
  const screenHeight = window.innerHeight // SIN devicePixelRatio es espacio para "dibujar", CON son pixeles reales

  if (screenWidth < minimunSize || screenHeight < minimunSize) return false

  return true
}

export const isDeviceSupported = () => {
  const deviceType = getDeviceType()

  switch (deviceType) {
    // case 'phone':
    //  return false

    default: {
      if (!isLargerThanMinimunSize()) return false
    }
  }

  return true
}

export const getDeviceType = (customUserAgent) => {
  const ua = (customUserAgent || navigator.userAgent).toLowerCase()
  let deviceType = 'desktop'

  // Check if it's ChromeOS or Chromebook.
  if (/cros/.test(ua) || /chromebook/.test(ua)) {
    return deviceType
  }

  // Las tablets iPad pueden usar como user agent el de un mac por eso añadimos este punto
  if (isIosDevice()) {
    if (ua.includes('iphone')) {
      deviceType = 'phone'
    } else {
      deviceType = 'tablet'
    }
  }
  // Si no es un dispositivo iOS se usa el método standar
  else if (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      ua
    )
  ) {
    deviceType = 'tablet'
  } else if (
    /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(
      ua
    )
  ) {
    deviceType = 'phone'
  }

  return deviceType
}

export const isIosDevice = (customUserAgent) => {
  // El user agent se fuerza porque a veces los iPad tiene userAgent de Mac
  //  se añade la comprobación adicional de si es táctil
  //  (mejor algún falso positivo que muchos falsos negativos en este caso)
  const userAgent = (customUserAgent || navigator.userAgent).toLowerCase()
  const isIOSUserAgent = /ipad|iphone|mac\sos/i.test(userAgent)
  const isTouchDevice = navigator.maxTouchPoints > 1

  return isIOSUserAgent && isTouchDevice
}

export const isAndroidDevice = (customUserAgent) => {
  const userAgent = (customUserAgent || navigator.userAgent).toLowerCase()
  return /android/i.test(userAgent)
}

// Para pruebas ---------------------------------------
const userDeviceInfo = (customUserAgent) => {
  return {
    deviceType: getDeviceType(customUserAgent),
    isIos: isIosDevice(customUserAgent),
    isAndroid: isAndroidDevice(customUserAgent)
  }
}
window.userDeviceInfo = userDeviceInfo
