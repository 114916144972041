import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import Unlock from '../../assets/unlock.svg'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import { store } from '../../../../../store/configureStore'
import {
  setMoveCameraToPlayer,
  setMoveCameraToObject
} from '../../../../../store/MetaberrySlice/MetaberrySlice'
import translate from '../../../../../i18n/translate'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { ReactComponent as MessageFrameSvg } from '../../../../../assets/img/metaberry_ui/information-frame.svg'
import {
  getPanelRenderCount,
  updatePanelRenderCount,
  getSoundAssistance,
  getEffectsUserConfig
} from '../../../../../services/settingsService'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import PopupWrapped from '../../PopupWrapped'
const langCode = getCurrentLangString()
const audioFile = 'onboardingFirstBatteryUnblocked_' + langCode

const RewardBatteryUnlock = ({ handleCheck }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  useEffect(() => {
    // Hasta que termine el movimiento de cámara el popup ha de ser invisible
    const parent = document.querySelector('.popup-manager')
    if (parent) parent.className += ' popup-manager-transparent'

    updatePanelRenderCount(getPanelRenderCount() + 1)

    store.dispatch(setMoveCameraToObject({ active: true }))

    return () => {
      updatePanelRenderCount(getPanelRenderCount() - 1)
    }
  }, [])

  useSelector((state) => {
    const moveCameraToObjectIndex = state.metaberry.moveCameraToObjectIndex
    if (moveCameraToObjectIndex === -1 && !isPopupVisible) {
      const parent = document.querySelector('.popup-manager')
      if (parent) parent.className = 'popup-manager'

      setIsPopupVisible(true)
      getSoundAssistance() &&
        getEffectsUserConfig() === 1 &&
        playSoundEffect(audioFile)
    }
  })

  const onClose = () => {
    handleCheck()

    // Mover al personaje
    store.dispatch(setMoveCameraToPlayer(true))
  }

  return (
    isPopupVisible && (
      <>
        <PopupWrapped>
          <div className="reward-popup__backdrop" onClick={onClose}></div>
          <div className="reward-popup__information-container-with-image centered">
            {renderSoundFXController()}
            <div className="onboarding-popup__close-icon-new" onClick={onClose}>
              <CloseIcon />
            </div>
            <div className="onboarding-popup__bb-image-container">
              <img className="onboarding-popup__bb-image" src={Unlock} alt="" />
            </div>
            <div className="onboarding-popup__text-container-little">
              <MessageFrameSvg
                preserveAspectRatio="none"
                className="onboarding-popup__text-little-background"
              />
              <p className="onboarding-popup__little-text">
                {translate('reward_unlock_battery')}
              </p>
              <div className="onboarding-popup__little-audio">
                <AudioBtn
                  onClick={() => {
                    playSoundEffect(audioFile)
                  }}
                />
              </div>
            </div>
          </div>
        </PopupWrapped>
      </>
    )
  )
}
export default RewardBatteryUnlock
