const UNIT_TRANSPARENT_COLOR = 'rgba(0,0,0,0)'
const UNIT_RED_COLOR = '#EE3029'
const UNIT_ORANGE_COLOR = '#F0AE10'
const UNIT_GREEN_COLOR = '#00E19A'

export const getUnitProgressPercentAndColor = (
  completeLessonsTotal,
  lessonsTotal
) => {
  let color = UNIT_TRANSPARENT_COLOR
  let status = 'empty'
  const percent =
    lessonsTotal === 0
      ? 0
      : Math.round((100 * completeLessonsTotal) / lessonsTotal)

  if (percent === 100) {
    color = UNIT_GREEN_COLOR
    status = 'full_charge'
  } else if (percent >= 80) {
    color = UNIT_GREEN_COLOR
    status = 'high_charge'
  } else if (percent >= 36) {
    color = UNIT_ORANGE_COLOR
    status = 'medium_charge'
  } else if (percent > 0) {
    color = UNIT_RED_COLOR
    status = 'low_charge'
  }

  return { percent: percent, color: color, status: status }
}
