import React, { useEffect } from 'react'
import './BlueberryPopup.scss'
import CloseButton from '../../atoms/CloseButton/CloseButton'
import { store } from '../../../../../store/configureStore'
import { setIsGameKeyboardBlocked } from '../../../../../store/MetaberrySlice/MetaberrySlice'

const BlueberryPopup = ({ title, onClose, children }) => {
  useEffect(() => {
    store.dispatch(setIsGameKeyboardBlocked(true))

    return () => {
      store.dispatch(setIsGameKeyboardBlocked(false))
    }
  }, [])

  return (
    <div className="blueberry-popup">
      <div className="blueberry-popup_decoration"></div>
      <CloseButton onClick={onClose} />
      <div className="blueberry-popup_title">{title}</div>
      {children}
    </div>
  )
}

export default BlueberryPopup
