import React, { useEffect, useState } from 'react'
import translate from '../../../../../i18n/translate'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { getSoundAssistance } from '../../../../../services/settingsService'
import { Analysis } from '../../../../../services/analysisService'
import PopupWrapped from '../../PopupWrapped'

const OnboardingBatteryCharged = ({ handleCheck, pathSchool }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const langCode = getCurrentLangString()
  const audioFile = 'onboardingFirstBatteryCharged_' + langCode
  const [blueberroImage, setBlueberroImage] = useState()

  useEffect(() => {
    getSoundAssistance() && playSoundEffect(audioFile)

    setBlueberroImage(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/bb_happy.png`
        : `/assets/graphics/gamification/bb_happy.png`
    )
  }, [])

  const handleAudio = () => {
    Analysis.sendEvent(Analysis.EVENT.REQUEST_TEXT_TO_SPEECH, {
      type: 'onboarding'
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['TTS Clicked'], {
      object: 'onboarding',
      ui_element_id: 'onboarding_battery_charged',
      seed_id: undefined
    })

    playSoundEffect(audioFile)
  }

  return (
    <>
      <PopupWrapped>
        <div className="onboarding-panel__backdrop" onClick={handleCheck}></div>
        <div className="onboarding-panel">
          {renderSoundFXController()}
          <div className="onboarding-panel__top"></div>
          <AudioBtn
            className="onboarding-panel__audio left"
            onClick={handleAudio}
          />
          <div className="onboarding-panel__close-icon" onClick={handleCheck}>
            <CloseIcon />
          </div>
          <div className="onboarding-panel__text">
            <p>{translate('onboarding_battery_charged_text_1')}</p>
            <p>{translate('onboarding_battery_charged_text_2')}</p>
          </div>
          <div className="onboarding-panel__bb-image">
            <img src={blueberroImage} alt="" />
          </div>
          <div className="onboarding-panel__bottom"></div>
        </div>
      </PopupWrapped>
    </>
  )
}
export default OnboardingBatteryCharged
