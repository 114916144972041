import { store } from '../../configureStore'
import { setDailyAccessReward } from '../RewardsSlice'

export default function setDailyAccessRewardHelper(gameEngineObject) {
  let claimableReward
  for (let i = 0; i < gameEngineObject.reward.length; i++) {
    if (gameEngineObject.reward[i].claimableReward.id === 1) {
      claimableReward = gameEngineObject.reward[i].claimableReward
      claimableReward.isClaimable = gameEngineObject.reward[i].isClaimable
      claimableReward.nextClaimTime = gameEngineObject.reward[i].nextClaimTime

      break
    }
  }

  store.dispatch(
    setDailyAccessReward({
      canGetDailyReward: claimableReward.isClaimable,
      nextDailyReward: claimableReward.nextClaimTime,
      amountDailyReward: claimableReward.rewardAmount,
      cooldownHours: claimableReward.cooldownHours
    })
  )
}
