import React from 'react'
import './UnitInfo.scss'

const UnitInfo = ({ index, name }) => {
  return (
    <div className="unit-info">
      <span className="unit-info_index">{index}</span>
      <span className="unit-info_name">{name}</span>
    </div>
  )
}

export default UnitInfo
