import React, { useEffect, useState } from 'react'
import './UnitFrame.scss'
import UnitImage from '../../atoms/UnitImage/UnitImage'
import UnitInfo from '../UnitInfo/UnitInfo'
import LessonsList from '../LessonsList/LessonsList'
import GoBackButton from '../../atoms/GoBackButton/GoBackButton'
import BasicButton from '../../atoms/BasicButton/BasicButton'
import getLessons from '../../../../views/MetaberryView/utils/getLessons'
import { Analysis } from '../../../../../services/analysisService'
import translate from '../../../../../i18n/translate'
import { getUnitProgressPercentAndColor } from '../../../../../helpers/calculateUnitProgress'
import { customStorage } from '../../../../../util/customStorage'

const UnitFrame = ({ unit, onGoBack, onEnterUnit }) => {
  const LoadingImage = `${process.env.PUBLIC_URL}/assets/graphics/images/loading.svg`
  const [lessons, setLessons] = useState([])
  const [isEnterButtonDisabled, setIsEnterButtonDisabled] = useState(false)

  useEffect(() => {
    const status = getUnitProgressPercentAndColor(
      unit.completeLessonsTotal,
      unit.lessonsTotal
    ).status

    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Progress Map Building Clicked'],
      {
        unit_id: unit.guid,
        unit_name: unit.name,
        unit_status: status
      }
    )

    getUnitLessons()
  }, [])

  const getUnitLessons = async () => {
    try {
      const _lessons = await getLessons(unit.guid)
      setLessons(_lessons)
    } catch (error) {
      console.error('Error getting unit lessons at UnitFrame:', error)
    }
  }

  const enterUnit = () => {
    customStorage.set('bb_enter_unit_from_progress_map', 1)

    setIsEnterButtonDisabled(true)
    onEnterUnit()
  }

  return (
    <div className="unit-frame">
      <GoBackButton onClick={onGoBack} />

      <div className="unit-frame_section-a">
        <UnitImage unitSrc={unit.image} alt={unit.name} />
        <BasicButton
          text={translate('unit_enter')}
          onClick={enterUnit}
          variant={'light'}
          disabled={isEnterButtonDisabled}
        />
      </div>
      <div className="unit-frame_section-b">
        <UnitInfo index={unit.index} name={unit.name} />
        {lessons.length > 0 && <LessonsList lessons={lessons} />}
        {lessons.length === 0 && (
          <div className="unit-frame_lossons-loading">
            <img src={LoadingImage} />
          </div>
        )}
      </div>
    </div>
  )
}

export default UnitFrame
