import React, { useEffect, useState } from 'react'
import anime from 'animejs'
import DailyGoalImg from '../../../assets/img/metaberry_ui/green_lightning.svg'
import translate from '../../../i18n/translate'
// import { Analysis } from '../../../services/analysisService'
import './mapDailyGoalProgressBar.scss'
import MapBarTooltip from '../MapBarTooltip/MapBarTooltip'

const MapDailyGoalProgressBar = ({
  progress,
  totalExperience,
  isFlagEnabledGamification
}) => {
  const rewardDailyGoal = new CustomEvent('reward-daily-goal')
  const percent = Math.min(Math.max((progress / totalExperience) * 100, 0), 100)
  const [currentPercent, setCurrentPercent] = useState(percent)
  const [isGlowing, setIsGlowing] = useState(false)

  const glowBar = () => {
    setIsGlowing(true)
    setTimeout(() => setIsGlowing(false), 5000)
  }

  useEffect(() => {
    setCurrentPercent(percent)
    animateProgressBar()
  }, [progress])

  function animateProgressBar() {
    anime({
      targets: '.map-daily-goal-progress-bar__progress',
      width: `${percent}%`,
      easing: 'linear',
      duration: 1000,
      complete: function (anim) {
        if (percent > currentPercent) {
          if (percent === 100) {
            !isFlagEnabledGamification && window.dispatchEvent(rewardDailyGoal)
            glowBar()
          }
        }
      }
    })
  }

  // Analysis.sendSegmentTrackEvent(
  //   Analysis.SEGMENT_EVENTS['Daily Goal Icon Clicked']
  // )

  return (
    <>
      <MapBarTooltip
        width={null}
        arrowPosition="center"
        message={
          percent < 100
            ? translate('info_tooltip_dailygoal')
            : translate('info_tooltip_dailygoal_completed')
        }
      >
        <div className={`map-daily-goal-progress-bar`}>
          <div className="map-daily-goal-progress-bar__icon">
            <img src={DailyGoalImg} alt="" />
          </div>
          <div className="map-daily-goal-progress-bar__bar-container">
            <div className="map-daily-goal-progress-bar__bg"></div>
            <div
              className={`map-daily-goal-progress-bar__progress ${
                percent === 100
                  ? 'map-daily-goal-progress-bar__progress--completed'
                  : ''
              } ${isGlowing ? 'green-shine' : ''}`}
              style={{ width: `${percent}%` }}
            ></div>
          </div>
        </div>
      </MapBarTooltip>
    </>
  )
}

export default MapDailyGoalProgressBar
