import { React, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import translate from '../../i18n/translate'
import { logout } from '../../services/logoutService'
import { Analysis } from '../../services/analysisService'
import DownloadAppStore from '../../assets/img/download_appstore.png'
import DownloadGooglePlay from '../../assets/img/download_googleplay.png'
import { getCourseGuid } from '../../assets/data/api'
import {
  ANDROID_FROM_NO_ANDROID,
  ANDROID_INTENT_WITHOUT_PARAMS,
  ANDROID_INTENT_WITH_PARAMS,
  IOS_STORE_URL
} from '../../config/constants/appSchemesAndStoreUrls'
import {
  isIosDevice,
  isAndroidDevice,
  getDeviceType,
  isDeviceSupported
} from '../../util/devices'

import './downloadAppPage.scss'
import { getToken } from '../../services/util'
import AccessHelper from '../../services/util/accessHelper'
import { getCurrentLangString } from '../../i18n/initI18n'
import { useSelector } from 'react-redux'

const DownloadAppPage = () => {
  const history = useHistory()

  const [isIPhone, setIsIPhone] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)
  const [ssoSource, setSsoSource] = useState('blueberrySso')
  const [courseDataLoaded, setCourseDataLoaded] = useState(false)
  const initialPathSchool = useSelector(
    (state) => state.configuration.pathSchool
  )
  const [pathSchool, setPathSchool] = useState(initialPathSchool)

  const [mapScreenImg, setMapScreenImg] = useState()

  useEffect(() => {
    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Device Not Supported Page Viewed'],
      {
        device_type: getDeviceType(),
        is_tablet: getDeviceType() === 'tablet',
        is_mobile: getDeviceType() === 'phone',
        resolution: window.innerWidth + 'x' + window.innerHeight,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio
      }
    )

    checkDevice()

    getCourseGuid().then(() => {
      setCourseDataLoaded(true)
    })

    // Check device
    const isiPhone = isIosDevice()
    const isAndroid = isAndroidDevice()

    setIsIPhone(isiPhone)
    setIsAndroid(isAndroid)

    const search = window.location.search
    const [, params] = search.split('?')
    if (params) {
      const urlParams = new URLSearchParams(params)
      const ssoSourceParam = urlParams.get('ssoSource')
      setSsoSource(ssoSourceParam)
    }

    setMapScreenImg(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/map-screen.png`
        : `/assets/graphics/gamification/map-screen.png`
    )

    // Send analytics event
    Analysis.sendEvent(Analysis.EVENT.UNSUPPORTED_SCREEN)

    let resizeTimeout
    const resizeListener = () => {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        checkDevice()
      }, 250) // Tiempo de debounce en milisegundos
    }

    window.addEventListener('resize', resizeListener)
    return () => window.removeEventListener('resize', resizeListener)
  }, [])

  useEffect(() => {
    setPathSchool(initialPathSchool)
    setMapScreenImg(
      initialPathSchool
        ? `/assets/graphics/gamification/${initialPathSchool}/map-screen.svg`
        : `/assets/graphics/gamification/map-screen.png`
    )
  }, [initialPathSchool])

  const checkDevice = () => {
    if (isDeviceSupported()) {
      history.replace('/game')
    }
  }

  const handleLogout = () => {
    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Logout Button Click']
    )
    logout()
  }

  const openIOS = () => {
    // This will redirect to App store
    let language = getCurrentLangString()
    if (language === 'es' || language === 'pt') {
      language = '/' + language
    } else language = ''

    window.location = IOS_STORE_URL.replace('[LANGUAGE]', language)
  }

  const openAndroid = () => {
    const accessInformation = AccessHelper.getAccessInformation()
    if (
      accessInformation &&
      accessInformation.accessType === AccessHelper.ACCESS_TYPE_BB_SSO
    ) {
      const intent = ANDROID_INTENT_WITH_PARAMS.replace('[SOURCE]', ssoSource)
        .replace('[TOKEN]', getToken())
        .replace('[HOST]', window.location.hostname)

      window.location = intent
    } else {
      window.location = ANDROID_INTENT_WITHOUT_PARAMS
    }
  }

  return (
    <div className="download-app-page">
      <div className="download-app-page-layout">
        <div className="download-app-page-layout__container">
          {courseDataLoaded && (
            <div className="download-app-page__image">
              <img src={mapScreenImg} alt="" />
            </div>
          )}
          <div className="download-app-page__title">
            {translate('download_app_page_title')}
          </div>
          <div className="download-app-page__text">
            {translate('download_app_page_text')}
          </div>
          {!isIPhone && !isAndroid && (
            <>
              <div className="download-app-page__download-btn">
                <img src={DownloadAppStore} onClick={openIOS} />
              </div>
              <div className="download-app-page__separator">
                <span className="download-app-page__separator-line"></span>
                <span className="download-app-page__separator-text">o</span>
                <span className="download-app-page__separator-line"></span>
              </div>
              <div className="download-app-page__download-btn">
                <a href={ANDROID_FROM_NO_ANDROID}>
                  <img src={DownloadGooglePlay} />
                </a>
              </div>
            </>
          )}
          {isIPhone && (
            <div className="download-app-page__download-btn">
              <img src={DownloadAppStore} onClick={openIOS} />
            </div>
          )}

          {isAndroid && (
            <div className="download-app-page__download-btn">
              <img src={DownloadGooglePlay} onClick={openAndroid} />
            </div>
          )}
        </div>

        <div className="download-app-page__logout" onClick={handleLogout}>
          {translate('download_app_page_logout')}
        </div>
      </div>
    </div>
  )
}

export default DownloadAppPage
