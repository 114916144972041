import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SvgScaffold } from '../../../assets/icons/chevrons-up.svg'
import { ReactComponent as SvgLightBulb } from '../../../assets/icons/light-bulb.svg'
import { ACTIVITY_TYPES } from '../../../constants/ActivityConstants'
import { BREAKPOINTS } from '../../../constants/uiConstants'
import { useActivityContext } from '../../../context/ActivityContext/useActivityContext'
import ActivityButton from '../../atoms/ActivityButton/ActivityButton'
import FeedbackIndicator from '../../atoms/FeedbackIndicator/FeedbackIndicator'
import './activityFooter.scss'
import { useActivityFooter } from './useActivityFooter'

function ActivityFooter({
  checkAnswer,
  showScaffoldFirstStep,
  feedbackIndicatorObject,
  setIsFeedbackVisible,
  resetActivity,
  onActivityEvent,
  isResponseSend = false,
  activityWidth,
  hasCheckExerciseAPiResponded,
  scaffoldNext,
  pathAssetsSchool
}) {
  const { t } = useTranslation()

  const {
    activityType,
    isPracticeType,
    activityStore,
    isCheckButtonEnabled,
    isAnswerCorrect,
    isScaffold,
    scaffoldCurrentQuestion,
    isScaffoldComplete
  } = useActivityContext()

  // TODO los que pueda van en useActivityManager vv
  const {
    toogleHint,
    toogleSolutions,
    continuePractice,
    shouldUserViewSolution
  } = useActivityFooter({
    setIsFeedbackVisible,
    onActivityEvent
  })

  const footerRef = useRef(null)
  const [footerWidth, setFooterWidth] = useState(window?.innerWidth || 0)
  const [widthClass, setWidthClass] = useState('')

  useEffect(() => {
    // setFooterWidth(footerRef.current.parentNode.offsetWidth)
    setFooterWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    let _widthClass = ''

    if (activityWidth < BREAKPOINTS.desktop) {
      if (activityWidth < BREAKPOINTS.tablet) {
        _widthClass = ' activity-footer_phone-size'
      } else {
        _widthClass = ' activity-footer_tablet-size'
      }
    }

    setWidthClass(_widthClass)
  }, [activityWidth])

  const handleResize = () => {
    setFooterWidth(window.innerWidth)
  }

  const moveForward = () => {
    resetActivity()

    if (isPracticeType) {
      continuePractice()
    }
  }

  return (
    <div className={'activity-footer' + widthClass} ref={footerRef}>
      {/* Antes de evaluar */}
      {activityStore?.isEvaluated === false && (
        <>
          {/* Botón pista / ayuda paso a paso (scaffold) */}
          {activityType !== ACTIVITY_TYPES.QUIZ &&
            (!isScaffold || scaffoldCurrentQuestion?.isMain) && (
              <ActivityButton
                icon={!isScaffold ? <SvgLightBulb /> : <SvgScaffold />}
                type={3}
                text={
                  footerWidth < BREAKPOINTS.tablet
                    ? ''
                    : t(
                        !isScaffold
                          ? 'activity_hint_button'
                          : 'activity_scaffold_button'
                      )
                }
                swapIconText={true}
                keepActive={!isScaffold}
                minimunSize={true}
                action={!isScaffold ? toogleHint : showScaffoldFirstStep}
              />
            )}

          {/* Como durante el scaffolding (preguntas secundarias) no hay botón de pista 
          se agrega este div vacío para que el de Comprobar quede a la derecha */}
          {isScaffold && !scaffoldCurrentQuestion?.isMain && <div></div>}

          {/* Botón comprobar */}
          {((activityType === ACTIVITY_TYPES.QUIZ && !isResponseSend) ||
            activityType !== ACTIVITY_TYPES.QUIZ) && (
            <ActivityButton
              text={
                activityType !== ACTIVITY_TYPES.QUIZ
                  ? t('activity_check_button')
                  : t('app_practice_activity_submit')
              }
              disabled={!isCheckButtonEnabled}
              action={checkAnswer}
            />
          )}
        </>
      )}

      {/* Tras evaluar */}
      {activityStore?.isEvaluated === true && (
        <>
          {footerWidth >= BREAKPOINTS.tablet &&
            (isAnswerCorrect || shouldUserViewSolution()) &&
            feedbackIndicatorObject?.message && (
              <FeedbackIndicator
                message={feedbackIndicatorObject.message}
                type={feedbackIndicatorObject.type}
                variant={feedbackIndicatorObject.variant}
                pathAssetsSchool={pathAssetsSchool}
              />
            )}

          {/* Soluciones por defecto / Soluciones usuario */}
          {!isAnswerCorrect && (
            <ActivityButton
              text={t(
                !activityStore.showSolution
                  ? 'activity_solution_button'
                  : 'activity_user_solution_button'
              )}
              disabled={false /* !isFeedbackReady && !isScaffold */}
              action={toogleSolutions}
              type={shouldUserViewSolution() ? 0 : 2}
            />
          )}

          {/* Siguiente ejercicio / salir */}
          {(!isScaffold || isScaffoldComplete) && !shouldUserViewSolution() ? (
            <ActivityButton
              text={t(
                isPracticeType
                  ? 'activity_continue_button'
                  : 'activity_restart_button'
              )}
              disabled={isPracticeType ? !hasCheckExerciseAPiResponded : false}
              isLoading={isPracticeType ? !hasCheckExerciseAPiResponded : false}
              action={moveForward}
            />
          ) : null}

          {/* Mostrar pasos de scaffold */}
          {isScaffold && !isScaffoldComplete && !shouldUserViewSolution() && (
            <ActivityButton
              text={t('activity_next_button')}
              action={scaffoldNext}
              disabled={isScaffoldComplete === undefined}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ActivityFooter
