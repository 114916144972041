import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  rankings: {},
  loading: false,
  error: null
}

const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    setRanking: (state, action) => {
      const { unitGuid, ranking } = action.payload
      state.rankings[unitGuid] = ranking
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  }
})

export const { setRanking, setLoading, setError } = rankingSlice.actions

export default rankingSlice.reducer
