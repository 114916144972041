import React, { useEffect, useState } from 'react'
import { ReactComponent as StarsBackgroundSvg } from '../../../assets/img/metaberry_ui/daily_goal_bg.svg'
import { ReactComponent as WhiteStarsBackgroundSvg } from '../../../assets/img/metaberry_ui/white-stars.svg'
import { ReactComponent as ShieldSvg } from '../../../assets/img/metaberry_ui/level_shield.svg'
import { ReactComponent as ShadowSvg } from '../../../assets/img/metaberry_ui/shadow.svg'
import { ReactComponent as CoinsSvg } from '../../../assets/img/metaberry_ui/coins.svg'
import { ReactComponent as RibbonSvg } from '../../../assets/img/metaberry_ui/ribbon_blue.svg'
import { ReactComponent as CloseSvg } from '../../../assets/icons/icon_xclose.svg'
import useSound from '../../views/MetaberryView/hooks/useSound'
import './informationPopup.scss'
import PracticeReward from '../../../pages/PracticeReward/PracticeReward'
import { getSoundAssistance } from '../../../services/settingsService'
import TitleContainer from '../TitleContainer/TitleContainer'

const CoinsGroup = ({ coins }) => {
  return (
    <div className="information-coins-group">
      <StarsBackgroundSvg className="information-coins-group__background" />
      <CoinsSvg className="information-coins-group__coins-image" />
      <RibbonSvg className="information-coins-group__ribbon-image" />
      <div className="information-coins-group__coins-number">{'+' + coins}</div>
    </div>
  )
}

const StarsBackgroundComponents = {
  levelUp: '',
  generalPopup: StarsBackgroundSvg,
  endOfChallenge: WhiteStarsBackgroundSvg
}

const LevelGroup = ({ children, context }) => {
  const StarsBackgroundSvg = StarsBackgroundComponents[context]
  return (
    <div className="information-level-group">
      <StarsBackgroundSvg className="information-level-group__background" />
      {children}
      <ShadowSvg
        preserveAspectRatio="none"
        className="information-level-group__shadow"
      />
    </div>
  )
}

const InformationPopup = ({
  message,
  level,
  coins,
  closeAction,
  buttonText,
  buttonAction,
  showCloseIcon,
  practiceProgressData,
  setPracticeBaseData,
  earnedPiecesAux,
  earnedCoinsAux,
  earnedExperienceAux,
  isChallengeEnd,
  batteryIcon,
  completeTitle,
  className,
  audioFile
}) => {
  const { renderSoundFXController, playSoundEffect } = useSound()

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [levelFontSize, setLevelFontSize] = useState(0)
  const [levelTop, setLevelTop] = useState(0)

  useEffect(() => {
    if (level) {
      playSoundEffect('levelUp')
    }

    if (audioFile) {
      if (getSoundAssistance()) {
        setTimeout(() => playSoundEffect(audioFile), level ? 2100 : 0) // Para dar tiempo al sonido anterior (levelUp)
      }
    }

    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (level) {
      let newFontSize = Math.max(-13 * level.toString().length + 85, 24) // Min font size 24px
      let newTop = 8 * level.toString().length - 2

      if (windowWidth < 300) {
        newFontSize *= 0.8
        newTop *= 0.8
      }

      setLevelFontSize(newFontSize)
      setLevelTop(newTop)
    }
  }, [windowWidth, level?.length])

  const handleClickExterior = (event) => {
    if (closeAction && event.target === event.currentTarget) {
      closeAction && closeAction()
    }
  }

  return (
    <>
      {renderSoundFXController()}
      {!isChallengeEnd && (
        <div
          className="information-popup__backdrop"
          onClick={closeAction}
        ></div>
      )}

      <div
        className={`information-popup ${className}`}
        onClick={handleClickExterior}
      >
        {showCloseIcon && (
          <div
            className="information-popup_close_wrapper"
            onClick={closeAction}
          >
            <CloseSvg className="information-popup_close_image" />
          </div>
        )}

        {level && (
          <LevelGroup context="generalPopup">
            <>
              <ShieldSvg className="information-level-group__shield" />
              <div className="information-level-group__level">
                <div
                  style={{
                    fontSize: levelFontSize + 'px',
                    top: levelTop - 2
                  }}
                >
                  {level}
                </div>
              </div>
            </>
          </LevelGroup>
        )}

        {batteryIcon && (
          <LevelGroup context="endOfChallenge">
            <div className="information-level-group__batteryIcon">
              {batteryIcon}
            </div>
          </LevelGroup>
        )}

        {coins && <CoinsGroup coins={coins} />}

        <TitleContainer
          level={level}
          message={message}
          batteryIcon={batteryIcon}
          completeTitle={completeTitle}
          coins={coins}
          audioFile={audioFile}
          playSoundEffect={playSoundEffect}
        />

        {isChallengeEnd && (
          <PracticeReward
            practiceProgressData={practiceProgressData}
            earnedPiecesAux={earnedPiecesAux}
            earnedCoinsAux={earnedCoinsAux}
            earnedExperienceAux={earnedExperienceAux}
            setPracticeBaseData={setPracticeBaseData}
          />
        )}

        {buttonText && (
          <div className="information-popup_button" onClick={buttonAction}>
            {buttonText}
          </div>
        )}
      </div>
    </>
  )
}

export default InformationPopup
