import React from 'react'
import LessonItem from '../LessonItem/LessonItem'
import './LessonsList.scss'

const LessonsList = ({ lessons }) => {
  return (
    <div className="lessons-list">
      {lessons.map((lesson, index) => (
        <LessonItem key={index} lesson={lesson} />
      ))}
    </div>
  )
}

export default LessonsList
