// import extractDefaultLessonData from './extractDefaultLessonData'
/* eslint-disable no-unused-vars */

export function createUnits({ learningMapData }) {
  return learningMapData.map((mapData, idx) => {
    const {
      unit_guid,
      unit_name,
      is_available,
      is_practice_needed,
      status,
      ranking,
      lessons_total,
      complete_lessons_total,
      pieces
    } = mapData

    return {
      unit_guid,
      unit_name,
      is_available,
      is_practice_needed,
      status,
      ranking,
      lessons_total,
      complete_lessons_total,
      lessons: [],
      pieces
    }
  })
}
