import React from 'react'
import './contestPage.scss'
import logo from './assets/logo.svg'
import blueberroImage from './assets/insta_post-webinar.png'
import blueMonster from './assets/blueMonster.png'
import redMonster from './assets/redMonster.png'
import oneEyeGreenMonster from './assets/oneEyeGreenMonster.png'
import logoSimple from './assets/logotipo.png'
import instagram from './assets/instagramIcon.png'
import linkedin from './assets/linkedinIcon.png'
import youtube from './assets/youtubeIcon.png'
import planet from './assets/planet.png'

const ContestInfoPage = () => {
  return (
    <div className="contest-info__page">
      <div className="contest-info__page-bg">
        <div className="content__container">
          <div className="content__header">
            <img className="content__logo" src={logo} alt="Blueberry Math" />
            <img
              className="content__red-monster pulsate-fwd"
              src={redMonster}
              alt="Red Monster"
            />
          </div>
          <div className="contest-info-page__title">
            Preparados, listos… ¡arranca el Maratón Matemático!
          </div>
          <div className="content__highlight-text">
            El concurso comienza el próximo 4 de noviembre.
          </div>
          <div className="content__info">
            Desde Blueberry te traemos una oportunidad única para desafiar y
            motivar a tus estudiantes de Primaria: participa en nuestro maratón
            y fomenta el aprendizaje de matemáticas de una manera divertida y
            competitiva. Además, podrás conseguir licencias de la plataforma
            Blueberry Math para este curso.
          </div>
          <div className="content__images">
            <img
              className="content__boy-image"
              src={blueberroImage}
              alt="Boy with a computer"
            />
          </div>
          <div className="contest__requirements-content">
            <img
              className="content__one-eye-green-monster pulsate-fwd"
              src={oneEyeGreenMonster}
              alt="Green Monster"
            />
            <div className="contest__requirement-title">
              ¿Qué necesitas saber?
            </div>
            <div className="contest__requirement-info">
              <p>
                <strong>Fechas del concurso:</strong> Del 4 al 8 de noviembre.
              </p>
              <p>
                <strong>Fecha máxima de inscripción: </strong>Viernes 25 de
                octubre.
              </p>
            </div>

            <div className="contest__requirement-info">
              <p>
                La clase que <strong>más actividades correctas realice</strong>{' '}
                será premiada con{' '}
                <strong>licencias gratuitas de Blueberry Math</strong> para
                <strong> todos los estudiantes.</strong> ¡Las clases que queden
                en <strong>segundo y tercer lugar</strong> también tienen
                premio!
              </p>
              <p>
                <strong>Anuncio de los ganadores: </strong>Lunes 11 de
                noviembre.
              </p>

              <a
                href="https://oneclickresearch.typeform.com/maratonmates?typeform-source=one-click.atlassian.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="button" className="contest_button">
                  {' '}
                  Registra tu clase ahora y participa
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="contents__footer">
        <div className="contents__footer-redes">
          <a
            href="https://www.instagram.com/blueberry_math/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="instagram" src={instagram} alt="Instagram" />
          </a>
          <a
            href="https://www.linkedin.com/showcase/blueberrymath/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="linkedin" src={linkedin} alt="Linkedin" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCdeFPf80P9ZRlaThqUf1cmg"
            target="_blank"
            rel="noreferrer"
          >
            <img className="youtube" src={youtube} alt="Youtube" />
          </a>
        </div>

        <div className="contents__footer-web">
          <img className="planetImage" src={planet} alt="Planet" />
          <a
            href="https://www.blueberrymath.ai/ "
            target="_blank"
            rel="noreferrer"
          >
            <p>Visita nuestra web</p>
          </a>
        </div>
        <div className="layouts-demo__footer-logo">
          <img src={logoSimple} alt="Blueberry" />
        </div>
        <img
          className="content__blue-monster pulsate-fwd"
          src={blueMonster}
          alt="Blue Monster"
        />
      </div>
    </div>
  )
}

export default ContestInfoPage
