import React, { useEffect, useState } from 'react'
import './buildingProgressUnblockedCard.scss'
import { ReactComponent as GearSvg } from './../../../../assets/img/metaberry_ui/gold-gear.svg'
import TitleContainer from '../../../atoms/TitleContainer/TitleContainer'
import { getUnitProgressPercentAndColor } from '../../../../helpers/calculateUnitProgress'
import translate from '../../../../i18n/translate'

const BuildingProgressUnblockedCard = ({ index, unit, onClick }) => {
  const LightImage = `${process.env.PUBLIC_URL}/assets/graphics/images/light.png`
  const BatteriesPath = 'assets/graphics/batteries/stand-batteries/'

  const [percentAndColor, setPercentAndColor] = useState({
    percent: 0,
    color: 'rgba(0,0,0,0)'
  })

  const [percentPieces, setPercentPieces] = useState(0)

  useEffect(() => {
    setPercentAndColor(
      getUnitProgressPercentAndColor(
        unit.complete_lessons_total,
        unit.lessons_total
      )
    )
    selectBatteryIcon(percentAndColor)

    setPercentPieces(Math.round((100 * unit.pieces.have) / unit.pieces.pieces))
  }, [])

  const selectBatteryIcon = (percentage) => {
    if (percentage > 80) {
      return BatteriesPath + 'Complete_icon_battery.png'
    } else if (percentage >= 36) {
      return BatteriesPath + 'Medium_icon_battery.png'
    } else if (percentage > 0) {
      return BatteriesPath + 'Low_icon_battery.png'
    } else {
      return BatteriesPath + 'empty_icon_battery.png'
    }
  }
  const iconSource = selectBatteryIcon(percentAndColor.percent)

  const handleCardClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      className="building-progress-unblocked-card__container"
      onClick={handleCardClick}
    >
      <div className="building-progress-unblocked-card__white-circle-number">
        {index}
      </div>
      <div className="building-progress-unblocked-card__image-container">
        <img
          className="building-progress-unblocked-card__building-image"
          src={unit.image}
          alt="Building"
        />
        <img
          className="building-progress-unblocked-card__light-image"
          src={LightImage}
          alt="light"
        />
      </div>
      <TitleContainer titleText={unit.unit_name} />
      <div className="building-progress-unblocked-card__content-container">
        <div className="building-progress-unblocked-card__progress-info">
          <p className="building-progress-unblocked-card__progress-text">
            {translate('progress_map_energy_level')}
          </p>
          <div className="building-progress-unblocked-card__progress-bar">
            <div
              className="building-progress-unblocked-card__progress-level"
              style={{
                width: percentAndColor.percent + '%',
                backgroundColor: percentAndColor.color
              }}
            ></div>
            <span className="building-progress-unblocked-card__progress-percentage">
              {percentAndColor.percent + '%'}
            </span>
            <div className="building-progress-unblocked-card__progress-icon">
              <img src={iconSource} className="progress-icon__batteries" />
            </div>
          </div>
        </div>

        <div className="building-progress-unblocked-card__progress-info">
          <p className="building-progress-unblocked-card__progress-text">
            {translate('progress_map_pieces_amount')}
          </p>
          <div className="building-progress-unblocked-card__progress-bar">
            <div
              className="building-progress-unblocked-card__progress-level"
              style={{ width: percentPieces + '%' }}
            ></div>
            <span className="building-progress-unblocked-card__progress-percentage">
              {unit.pieces.have + '/' + unit.pieces.pieces}
            </span>
            <div className="building-progress-unblocked-card__progress-icon">
              <GearSvg className="progress-icon__size" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuildingProgressUnblockedCard
