export const customStorage = (() => {
  const get = (key, defaultValue = null) => {
    try {
      const value = window.localStorage.getItem(key)
      return value ? JSON.parse(value) : defaultValue
    } catch (error) {
      console.error(`Error reading '${key}' from localStorage`, error)
      return defaultValue
    }
  }

  const set = (key, value) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(`Error writing '${key}' to localStorage`, error)
    }
  }

  const remove = (key) => {
    try {
      window.localStorage.removeItem(key)
    } catch (error) {
      console.error(`Error deleting '${key}' from localStorage`, error)
    }
  }

  const clear = () => {
    try {
      window.localStorage.clear()
    } catch (error) {
      console.error(`Error clearing localStorage`, error)
    }
  }

  return {
    get,
    set,
    remove,
    clear
  }
})()

// Ejemplo de uso:
//  customStorage.get('key', [])
//  customStorage.set('key', data)
//  customStorage.remove('key')
//  customStorage.clear()
