import React from 'react'
import './BasicButton.scss'

const BasicButton = ({ text, onClick, variant, outline, disabled }) => {
  // variants: light
  const variantClass = variant ? ' basic-button_' + variant : ''
  const outlineClass = outline ? ' basic-button-outlined' : ''

  const buttonAction = () => {
    if (onClick && !disabled) {
      onClick()
    }
  }

  return (
    <button
      className={'basic-button' + variantClass + outlineClass}
      onClick={buttonAction}
      aria-label={text}
    >
      <span>{text}</span>
    </button>
  )
}

export default BasicButton
