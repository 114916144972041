import React, { useCallback } from 'react'
import { ReactComponent as AlertIcon } from '../../../assets/img/metaberry_ui/alert.svg'
import translate from '../../../i18n/translate'
import { logout } from '../../../services/logoutService'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Analysis } from '../../../services/analysisService'

const MetaLogoutModal = ({ onClose }) => {
  const history = useHistory()
  const isDevMode = useSelector((state) => state.configuration.isDevMode)

  const handleLogout = () => {
    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Logout Button Click']
    )

    logout()
  }

  const handleReset = useCallback(() => {
    history.replace('/reset-db/0')
  }, [])

  const handleSalesReset = useCallback(() => {
    history.replace('/reset-db/2')
  }, [])

  return (
    <>
      <div className="meta-logout-modal__backdrop" onClick={onClose}></div>
      <div className="meta-logout-modal">
        <div className="meta-logout-modal__header">
          <div className="meta-logout-modal__header-icon">
            <AlertIcon />
          </div>
          <div className="meta-logout-modal__header-text">
            {translate('logout_warning')}
          </div>
        </div>

        <div className="meta-logout-modal__content">
          {translate('logout_message')}
        </div>
        <div className="meta-logout-modal__actions">
          <div
            className="meta-logout-modal__button meta-logout-modal__button--confirm"
            onClick={handleLogout}
            data-testid="logout_confirm"
          >
            {translate('logout_confirm')}
          </div>

          <div
            className="meta-logout-modal__button meta-logout-modal__button--cancel"
            onClick={onClose}
          >
            {translate('logout_cancel')}
          </div>
        </div>

        {!!isDevMode && (
          <div className="meta-logout-modal__dev-options">
            <div
              className="meta-logout-modal__button meta-logout-modal__button--warning"
              onClick={handleReset}
            >
              {translate('reset_label')}
            </div>
            <div
              className="meta-logout-modal__button meta-logout-modal__button--warning"
              onClick={handleSalesReset}
            >
              {translate('reset_sales_label')}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default MetaLogoutModal
