import React, { useEffect, useState } from 'react'
import MetaRankingItem from '../MetaRankingItem/MetaRankingItem'
import translate from '../../../../../i18n/translate'
import { getCourseGuid } from '../../../../../assets/data/api'
import { fetchWithToken, createEndPoint } from '../../../../../services/util'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { Analysis } from '../../../../../services/analysisService'

const MetaRanking = ({
  ranking,
  currentUser,
  unitGuid,
  unitName,
  pathSchool,
  loadingRanking,
  isGamification
}) => {
  const [fullRanking, setFullRanking] = useState(false) // Indica si se muestra o no todo el ranking
  const [completeRankingLoaded, setCompleteRankingLoaded] = useState(false)
  const [currentRanking, setCurrentRanking] = useState(ranking) // Almacena el ranking parcial al inicio y completo si se solicita
  const { renderSoundFXController, playSoundEffect } = useSound()

  useEffect(() => {
    setCurrentRanking(ranking)
  }, [ranking])

  const toggleRanking = async () => {
    playSoundEffect('expand')

    Analysis.sendEvent(
      !fullRanking ? Analysis.EVENT.OPEN_RANKING : Analysis.EVENT.CLOSE_RANKING,
      {
        unit_guid: unitGuid,
        unit_name: unitName
      }
    )

    if (!fullRanking) {
      if (!completeRankingLoaded) {
        if (!isGamification) {
          const auxRanking = await getFullRanking()
          setCurrentRanking(auxRanking)
        }
        setCompleteRankingLoaded(true)
      }
    }

    setFullRanking(!fullRanking)
  }

  const getFullRanking = async () => {
    const courseGuid = await getCourseGuid()
    const rankingEndPoint = `lms/courses/${courseGuid}/blueberry/units/${unitGuid}/ranking`
    const rankingData = await fetchWithToken(
      createEndPoint(rankingEndPoint)
    ).then((json) => json.data)

    return rankingData
  }

  const renderFullRanking = () => {
    return currentRanking.map((item, index) => (
      <MetaRankingItem
        isCurrentUser={item.guid === currentUser}
        rank={item.rank}
        fullName={`${item.name} ${item.lastname}`}
        experience={item.exp}
        key={index}
        avatarImg={(() => {
          try {
            const settings = JSON.parse(item.json_settings || '{}')
            return settings?.blueberry?.avatar?.single_image || ''
          } catch (error) {
            console.error('Error al parsear json_settings:', error)
            return ''
          }
        })()}
        pathSchool={pathSchool}
      />
    ))
  }

  const getRankingItem = (arrayItem) => {
    return (
      <MetaRankingItem
        isCurrentUser={arrayItem.guid === currentUser}
        rank={arrayItem.rank}
        fullName={`${arrayItem.name} ${arrayItem.lastname}`}
        experience={arrayItem.exp}
        key={arrayItem.guid}
        avatarImg={(() => {
          try {
            const settings = JSON.parse(arrayItem.json_settings || '{}')
            return settings?.blueberry?.avatar?.single_image || ''
          } catch (error) {
            console.error('Error al parsear json_settings:', error)
            return ''
          }
        })()}
        pathSchool={pathSchool}
      />
    )
  }

  const renderFirstUsers = () => {
    const currentUserData = currentRanking.filter(
      (item) => item.guid === currentUser
    )[0]

    // Si el usuario actual está 1º o 2º O no está en la lista (profesores) se muestran lo 3 primeros
    if (!currentUserData || currentUserData.rank < 3) {
      return currentRanking.map(
        (item, index) => index < 3 && getRankingItem(item)
      )
    } else {
      // Si el usuario actual está entre el 3º y pénultimo mostramos el 1º y el que está por encima y debajo del usuario actual
      if (
        currentUserData.rank >= 3 &&
        currentUserData.rank < currentRanking.length
      ) {
        return currentRanking.map((item) => {
          return item.rank === 1 ||
            item.rank === currentUserData.rank - 1 ||
            item.rank === currentUserData.rank + 1 ||
            item.rank === currentUserData.rank
            ? getRankingItem(item)
            : null
        })
      } else {
        // Si el usuario actual está en la última posición mostramos el 1º y el que está por encima del usuario actual
        return currentRanking.map((item) => {
          return item.rank === 1 ||
            item.rank === currentUserData.rank - 1 ||
            item.rank === currentUserData.rank
            ? getRankingItem(item)
            : null
        })
      }
    }
  }

  return (
    <div className="meta-ranking">
      {renderSoundFXController()}
      <div className="meta-ranking__header">
        <div className="meta-ranking__title">
          {translate('unit_ranking_title')}
        </div>
        {currentRanking.length > 0 && (
          <div className="meta-ranking__button" onClick={() => toggleRanking()}>
            {fullRanking
              ? translate('unit_ranking_see_less')
              : translate('unit_ranking_see_all')}
          </div>
        )}
      </div>

      <div className="meta-ranking__content">
        <div className="meta-ranking__scroll-wrapper">
          {currentRanking.length === 0 && (
            <div className="meta-ranking__no-data">
              {loadingRanking ? (
                <div className="loader">{translate('map_loading_text')}</div>
              ) : (
                translate('unit_ranking_no_results')
              )}
            </div>
          )}
          {currentRanking.length > 0 && fullRanking
            ? renderFullRanking()
            : renderFirstUsers()}
        </div>
      </div>
    </div>
  )
}

export default MetaRanking
