import React, { useEffect } from 'react'

const PopupWrapped = ({ children }) => {
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('disable-game'))
    return () => {
      window.dispatchEvent(new CustomEvent('enable-game'))
    }
  }, [])
  return <>{children}</>
}
export default PopupWrapped
