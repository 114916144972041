import React, { useState, useEffect } from 'react'

import OnboardingIntroductionPanel1 from './OnboardingIntroductionPanel1'
import OnboardingIntroductionPanel2 from './OnboardingIntroductionPanel2'
import OnboardingIntroductionPanel3 from './OnboardingIntroductionPanel3'
import OnboardingIntroductionPanel4 from './OnboardingIntroductionPanel4'
import OnboardingIntroductionPanel5 from './OnboardingIntroductionPanel5'

import { Analysis } from '../../../../../services/analysisService'
import MetaTabAvatar from '../../../MetaTabAvatar/MetaTabAvatar'

const OnboardingIntroduction = ({ handleCheck, pathSchool }) => {
  const [step, setStep] = useState(0)
  const [panel, setPanel] = useState(0)
  const [finalTransition, setFinalTransition] = useState(false)
  const [bgOnboardingPanel, setBgOnboardingPanel] = useState(
    pathSchool
      ? `/assets/graphics/gamification/${pathSchool}`
      : `/assets/graphics/gamification`
  )

  useEffect(() => {
    setBgOnboardingPanel(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}`
        : `/assets/graphics/gamification`
    )

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      // eslint-disable-next-line dot-notation
      Analysis.SEGMENT_EVENTS['Onboarding']
    )
    setTimeout(() => goToScreen(1), 1700)
  }, [])

  const goToScreen = (screenNumber, speed = 1700) => {
    setStep(screenNumber)
    setPanel(0)
    setTimeout(() => setPanel(screenNumber), speed)
  }

  const handleFinish = () => {
    setPanel(0)
    setFinalTransition(true)
    setTimeout(handleCheck, 2100)
  }

  return (
    <div
      className={`onboarding-intro ${finalTransition ? 'fade-out-final' : ''}`}
      data-testid="onboarding-intro"
    >
      <div
        className={`onboarding-intro__background ${step === 3 ? 'shake' : ''}`}
      >
        <img
          src={`${bgOnboardingPanel}/intro_bg_01-min.png`}
          alt=""
          className={`onboarding-intro__background-img ${
            step === 1 ? 'fade-in-start' : ''
          } ${step === 2 ? 'fade-out' : ''}`}
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 2 || step === 3 ? 'fade-in' : ''
          } ${step === 4 ? 'fade-out' : ''}`}
          src={`${bgOnboardingPanel}/intro_bg_02-min.png`}
          alt=""
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 3 ? 'fade-in-cross' : ''
          } ${step === 4 ? 'fade-out' : ''}`}
          src={`${bgOnboardingPanel}/intro_bg_03-min.png`}
          alt=""
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 4 ? 'fade-in' : ''
          } ${step === 5 ? 'fade-out' : ''}`}
          src={`${bgOnboardingPanel}/intro_bg_04-min.png`}
          alt=""
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 5 ? 'fade-in' : 'fade-out-final'
          }`}
          src={`${bgOnboardingPanel}/intro_bg_05-min.png`}
          alt=""
        />

        <img
          className={`onboarding-intro__background-img ${
            step === 6 ? 'fade-in' : 'fade-out-final'
          }`}
          src={`${bgOnboardingPanel}/intro_bg_06-min.png`}
          alt=""
        />
      </div>
      {panel === 1 && (
        <OnboardingIntroductionPanel1
          handleButton={() => goToScreen(2, 1000)}
          pathSchool={pathSchool}
        />
      )}
      {panel === 2 && (
        <OnboardingIntroductionPanel2
          handleButton={() => goToScreen(3, 500)}
          pathSchool={pathSchool}
        />
      )}
      {panel === 3 && (
        <OnboardingIntroductionPanel3
          handleButton={() => goToScreen(4)}
          pathSchool={pathSchool}
        />
      )}
      {panel === 4 && (
        <OnboardingIntroductionPanel4
          handleButton={() => goToScreen(5)}
          pathSchool={pathSchool}
        />
      )}
      {panel === 5 && (
        <OnboardingIntroductionPanel5
          handleButton={() => goToScreen(6, 0)}
          pathSchool={pathSchool}
        />
      )}
      {panel === 6 && (
        <div className="onboarding-intro__avatar-selector">
          <MetaTabAvatar isIntro={true} handleIntroContinue={handleFinish} />
        </div>
      )}
    </div>
  )
}
export default OnboardingIntroduction
