import html2canvas from 'html2canvas'

export const generateScreenshot = (element) => {
  return html2canvas(element, {
    scale: 1,
    allowTaint: true,
    useCORS: true
  })
    .then((canvas) => {
      const dataUrl = canvas.toDataURL('image/png')

      // Añade imagen a body para pruebas
      // const issueScreenshotImage = document.createElement('img')
      // issueScreenshotImage.src = dataUrl
      // document.body.appendChild(issueScreenshotImage)

      return dataUrl
    })
    .catch((error) => {
      console.error('Error while getting screenshot', error)
      return null
    })
}
