import React, { useRef, useCallback, useEffect } from 'react'
import { debounce } from '../../../../util/debounce'
import { customStorage } from '../../../../util/customStorage'
import './courseProgressContainer.scss'

const CourseProgressContainer = ({ children }) => {
  const elementRef = useRef()

  useEffect(() => {
    if (elementRef.current) {
      const previousScroll = customStorage.get('course-progress-scroll')
      if (previousScroll !== undefined && previousScroll !== null) {
        elementRef.current.scrollTop = previousScroll
      }
    }
  }, [elementRef.current])

  const saveScrollPosition = () => {
    const scrollPosition = elementRef?.current?.scrollTop
    if (scrollPosition !== undefined && scrollPosition !== null) {
      customStorage.set('course-progress-scroll', scrollPosition)
    }
  }

  // Usar useCallback para memorizar la función debounced
  const debouncedSaveScrollPosition = useCallback(
    debounce(saveScrollPosition, 200),
    []
  )

  return (
    <div
      className="course-progress-container"
      ref={elementRef}
      onScroll={debouncedSaveScrollPosition}
    >
      {children}
    </div>
  )
}

export default CourseProgressContainer
