import { getToken, clearToken } from './token'

const { fetch } = window

export const fetcher = (...args) =>
  fetch(...args).then((res) => {
    if (res.statusCode === 403) throw redirectToLogin()
    if (res.status === 403) throw redirectToLogin()
    if (res.status === 405) throw redirectTo405IfPositivoOrLayers()
    if (res.status === 400) throw redirectTo405IfPositivoOrLayers()
    return res.json()
  })

export function fetchNoToken(endpoint, postPayload) {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  if (postPayload) {
    options.method = 'POST'
    options.body = JSON.stringify(postPayload)
  }

  return fetcher(endpoint, options)
}

export function fetchWithToken(endpoint, hasToUseMainToken, payload, method) {
  const _token = getToken(hasToUseMainToken)

  const options = {
    method: 'GET',
    headers: {
      Authorization: _token,
      'Content-Type': 'application/json'
    }
  }

  if (payload) {
    options.method = method || 'POST'
    options.body = JSON.stringify(payload)
  }

  return fetcher(endpoint, options)
}

function redirectToLogin() {
  clearToken()
  window.location.href = `${window.location.protocol}//${window.location.host}/login`
}

function redirectTo405IfPositivoOrLayers() {
  // check if positivo and redirect to 405
  if (
    window.location.host.indexOf('positivo-hml.blueberry.app.br') > -1 ||
    window.location.host.indexOf('positivo.blueberry.app.br') > -1
  ) {
    window.location.href = `${window.location.protocol}//${window.location.host}/error-405`
  }

  // check if layers and redirect to 405-contact
  const urlParams = new URLSearchParams(window.location.search)
  const isLayers = urlParams.get('layers_session')
  // ocLog('isLayers', isLayers)
  if (isLayers) {
    window.location.href = `${window.location.protocol}//${window.location.host}/error-405-contact`
  }
}
