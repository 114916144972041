import React from 'react'
import './buildingProgressBlockedCard.scss'
import purpleRectangle from './../../../../assets/img/metaberry_ui/purple-rectangle.png'
import lockImage from './../../../../assets/img/metaberry_ui/lock.png'
import TitleContainer from '../../../atoms/TitleContainer/TitleContainer'

const BuildingProgressBlockedCard = ({ index, title }) => {
  return (
    <div className="building-progress-blocked-card__container">
      <img
        className="building-progress-blocked-card__purple-rectangle-background"
        src={purpleRectangle}
      />
      <div className="building-progress-blocked-card__white-circle-number">
        {index}
      </div>
      <div className="building-progress-blocked-card__content">
        <TitleContainer titleText={title} />
        <div className="building-progress-card__grey-circle">
          <img
            className="building-progress-card__lock-image"
            src={lockImage}
          ></img>
        </div>
      </div>
    </div>
  )
}

export default BuildingProgressBlockedCard
