/* eslint-disable prettier/prettier */
export const NORTH_AMERICA_COUNTRY_CODES = ["CA", "US", "MX", "GL", "BM", "TC", "VG", "VI", "PM"]
export const CENTRAL_AMERICA_COUNTRY_CODES = ["BZ", "CR", "SV", "GT", "HN", "NI", "PA", "AG", "BS", "BB", "CU", "DM", "DO", "GD", "HT", "JM", "LC", "VC", "TT", "PR", "KY", "GP", "MQ", "MS", "BL", "MF", "KN", "AI", "AW", "BQ", "CW", "SX"]
export const SOUTH_AMERICA_COUNTRY_CODES = ["AR", "BO", "BR", "CL", "CO", "EC", "GY", "PY", "PE", "SR", "UY", "VE", "FK", "GF"]
export const EUROPE_COUNTRY_CODES = ["AL", "AD", "AT", "BY", "BE", "BA", "BG", "HR", "CY", "CZ", "DK", "EE", "FO", "FI", "FR", "DE", "GI", "GR", "HU", "IS", "IE", "IM", "IT", "XK", "LV", "LI", "LT", "LU", "MK", "MT", "MD", "MC", "ME", "NL", "NO", "PL", "PT", "RO", "RU", "SM", "RS", "SK", "SI", "ES", "SJ", "SE", "CH", "UA", "GB", "VA", "AX"]
export const AFRICA_COUNTRY_CODES = ["DZ", "AO", "BJ", "BW", "BF", "BI", "CV", "CM", "CF", "TD", "KM", "CD", "DJ", "EG", "GQ", "ER", "SZ", "ET", "GA", "GM", "GH", "GN", "GW", "CI", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RE", "RW", "ST", "SN", "SC", "SL", "SO", "ZA", "SS", "SH", "SD", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW"]
export const ASIA_COUNTRY_CODES = ["AF", "AM", "AZ", "BH", "BD", "BT", "BN", "KH", "CN", "CY", "GE", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KP", "KR", "KW", "KG", "LA", "LB", "LK", "MY", "MV", "MN", "MM", "NP", "OM", "PK", "PS", "PH", "QA", "RU", "SA", "SG", "LK", "SY", "TJ", "TH", "TL", "TR", "TM", "AE", "UZ", "VN", "YE"]
export const OCEANIA_COUNTRY_CODES = ["AS", "AU", "CK", "FJ", "PF", "GU", "KI", "MH", "FM", "NR", "NC", "NZ", "NU", "NF", "MP", "PW", "PG", "PN", "WS", "SB", "TK", "TO", "TV", "UM", "VU", "WF", "WS"]
export const MIDDLE_EAST_COUNTRY_CODES = ["AE", "AM", "AZ", "BH", "CY", "GE", "IR", "IQ", "IL", "JO", "KW", "LB", "OM", "QA", "SA", "SY", "TR", "YE"]
export const POLAR_REGIONS_CODES = ["AQ"]
export const OCEANIC_ISLANDS_AND_TERRITORIES_CODES = ["PN", "BV", "GS"]
export const OTHER_ISO_CODES = ["ZZ", "EU"]