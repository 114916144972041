import React, { useEffect, useState } from 'react'
import translate from '../../../../../i18n/translate'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import { ReactComponent as MessageFrameSvg } from '../../../../../assets/img/metaberry_ui/information-frame.svg'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { getSoundAssistance } from '../../../../../services/settingsService'
import { Analysis } from '../../../../../services/analysisService'
import PopupWrapped from '../../PopupWrapped'

const OnboardingBatteryComplete = ({ handleCheck, pathSchool }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const langCode = getCurrentLangString()
  const audioFile = 'onboardingFirstBatteryComplete_' + langCode
  const [blueberroImage, setBlueberroImage] = useState()

  useEffect(() => {
    getSoundAssistance() && playSoundEffect(audioFile)

    setBlueberroImage(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/bb_happy.svg`
        : `/assets/graphics/gamification/bb_happy.svg`
    )
  }, [])

  const handleAudio = () => {
    Analysis.sendEvent(Analysis.EVENT.REQUEST_TEXT_TO_SPEECH, {
      type: 'onboarding'
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['TTS Clicked'], {
      object: 'onboarding',
      ui_element_id: 'onboarding_battery_complete',
      seed_id: undefined
    })

    playSoundEffect(audioFile)
  }

  return (
    <>
      <PopupWrapped>
        <div className="onboarding-popup__backdrop" onClick={handleCheck}></div>
        <div className="onboarding-popup__container-with-image centered">
          <div
            className="onboarding-popup__close-icon-new"
            onClick={handleCheck}
          >
            <CloseIcon />
          </div>
          <div className="onboarding-popup__bb-image-container">
            <img
              className="onboarding-popup__bb-image"
              src={blueberroImage}
              alt=""
            />
          </div>

          <div className="onboarding-popup__text-container">
            <MessageFrameSvg
              preserveAspectRatio="none"
              className="onboarding-popup__text-little-background"
            />
            <p className="onboarding-popup__little-text">
              {translate('onboarding_battery_complete_text_1')}
            </p>
            <div className="onboarding-popup__little-audio">
              {renderSoundFXController()}
              <AudioBtn
                onClick={() => {
                  handleAudio()
                  playSoundEffect(audioFile)
                }}
              />
            </div>
          </div>

          <div className="onboarding-panel__bottom"></div>
        </div>
      </PopupWrapped>
    </>
  )
}
export default OnboardingBatteryComplete
