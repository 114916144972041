import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import BlueberryFamilyLayout from './components/BlueberryFamilyLayout/BlueberryFamilyLayout'
import BlueberryFamilyPanel from './components/BlueberryFamilyPanel/BlueberryFamilyPanel'
import FormButton from './components/FormButton/FormButton'
import WelcomeImage from '../../assets/img/blueberro_fam_profile.png'
// import { ReactComponent as ProfileIcon } from '../../assets/icons/book.svg'
import translate from '../../i18n/translate'
import useFamilyMembers from './hooks/useFamilyMembers'
import { loginFamilyMember } from '../../services/loginService'
import {
  setFamilyUserData,
  getFamilyUserData
} from '../../services/userTypeService'
// import AccessHelper from '../../services/util/accessHelper'
import { resetPlayerState } from '../../services/userManagementService'
import usePopupManager from '../../modules/components/PopupManager/usePopupManager'
import { Analysis } from '../../services/analysisService'
import { getWhoAmI } from '../../services/courseInfoService'

const BlueberryFamilySelectProfile = ({ showMessage }) => {
  const history = useHistory()
  const { resetPopupStore } = usePopupManager()

  // Cambiar profiles number según haya o no perfiles
  const [familyMembers, setFamilyMembers] = useState([])
  const [hasFamilyMembers, setHasFamilyMembers] = useState(false)
  const [isGetFamilyMembersDone, setIsGetFamilyMembersDone] = useState(false)
  const hookMembers = useFamilyMembers()
  const [currentFamilyMemberData, setCurrentFamilyMemberData] = useState(null)
  const [mainUserGuid, setMainUserGuid] = useState(null)

  useEffect(() => {
    const sendAnalysisEvents = async () => {
      const whoAmI = await getWhoAmI(true)

      Analysis.sendEvent(Analysis.EVENT.CHOOSE_PLAYER_SCREEN)

      Analysis.sendSegmentPageEvent(
        showMessage
          ? Analysis.SEGMENT_PAGE_CATEGORIES.Signup
          : Analysis.SEGMENT_PAGE_CATEGORIES.Game,
        showMessage
          ? Analysis.SEGMENT_EVENTS['Select Player Page Viewed']
          : Analysis.SEGMENT_EVENTS['Change Player Page Viewed'],
        { user_id: whoAmI?.guid }
      )

      setMainUserGuid(whoAmI?.guid)
    }

    sendAnalysisEvents()

    setCurrentFamilyMemberData(getFamilyUserData())
  }, [])

  useEffect(() => {
    if (hookMembers.processDone) {
      setFamilyMembers(hookMembers.familyMembers)
      setHasFamilyMembers(hookMembers.familyMembers.length > 0)
      setIsGetFamilyMembersDone(true)
    }
  }, [hookMembers])

  const handleSelectMember = async (memberIndex) => {
    // ocLog(familyMembers[memberIndex])

    const memberCopy = structuredClone(familyMembers[memberIndex])
    memberCopy.player_id = memberCopy.guid
    delete memberCopy.guid

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['Select Player'], {
      user_id: mainUserGuid,
      ...memberCopy
    })

    Analysis.sendEvent(Analysis.EVENT.CHOOSE_PLAYER, {
      user_id: mainUserGuid,
      ...memberCopy
    })

    if (
      currentFamilyMemberData?.guid &&
      currentFamilyMemberData.guid !== familyMembers[memberIndex].guid
    ) {
      Analysis.sendEvent(Analysis.EVENT.SWITCH_PLAYER)
      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Player Changed'],
        {
          user_id: mainUserGuid,
          ...memberCopy
        }
      )
    }

    await loginFamilyMember(familyMembers[memberIndex].guid)

    setFamilyUserData(familyMembers[memberIndex])

    // Se resetea el GUID del curso y el estado del store y establecer acceso reciente y tipo
    // No debería hacer falta la información de acceso porque se hace en login y no cambia (sigue siendo el padre)
    // const accessInformation = AccessHelper.getAccessInformation()
    resetPlayerState()
    resetPopupStore()
    // AccessHelper.setAccessInformation(true, accessInformation.accessType)

    history.push('/game')
  }

  // const handleGoToProfile = () => ocLog('Go To Profile Click')
  const handleGoToAddProfile = () =>
    history.push('/blueberry-family-signup?toAddPlayers=true')

  return (
    <div
      className={`blueberry-family-select-profile ${
        !hasFamilyMembers ? 'blueberry-family-select-profile--no-profiles' : ''
      }`}
    >
      <BlueberryFamilyLayout>
        {isGetFamilyMembersDone && (
          <BlueberryFamilyPanel>
            {hasFamilyMembers && showMessage && (
              <div className="blueberry-family-panel__welcome">
                <img src={WelcomeImage} alt="" />
                <div>{translate('family_profile_select_welcome')}</div>
              </div>
            )}

            {hasFamilyMembers ? (
              <>
                <div className="blueberry-family-panel__title">
                  {translate('family_profile_select_title')}
                </div>
                {/*
                <div className="blueberry-family-panel__profile-button">
                  <FormButton
                    variant="transparent"
                    icon={<ProfileIcon />}
                    text={translate('family_profile_select_my_profile')}
                    textAlign="right"
                    onClick={handleGoToProfile}
                  />
                </div>
                */}

                <div className="blueberry-family-panel__actions blueberry-family-panel__actions--column">
                  {familyMembers.map((member, index) => {
                    return (
                      <FormButton
                        key={index}
                        text={member.name}
                        onClick={() => {
                          handleSelectMember(index)
                        }}
                      />
                    )
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="blueberry-family-panel__title">
                  {translate('family_profile_select_no_profile_title')}
                </div>
                <div className="blueberry-family-panel__text">
                  {translate('family_profile_select_no_profile_text')}
                </div>
                <FormButton
                  text={translate('family_profile_select_no_profile_button')}
                  onClick={handleGoToAddProfile}
                />
              </>
            )}
          </BlueberryFamilyPanel>
        )}
      </BlueberryFamilyLayout>
    </div>
  )
}

export default BlueberryFamilySelectProfile
