import React from 'react'
import './UnitImage.scss'

const UnitImage = ({ unitSrc, alt }) => {
  const StarImage = `${process.env.PUBLIC_URL}/assets/graphics/images/star.png`
  const LightImage = `${process.env.PUBLIC_URL}/assets/graphics/images/light.png`

  return (
    <div className="unit-image">
      <img className="unit-image_star" src={StarImage} alt={alt + '-star'} />
      <img className="unit-image_building" src={unitSrc} alt={alt} />
      <img className="unit-image_light" src={LightImage} alt={alt + '-light'} />
    </div>
  )
}

export default UnitImage
