import React, { useState } from 'react'
import './titleContainer.scss'
import Title from '../Title/Title'
import { ReactComponent as AudioButton } from '../../../assets/img/metaberry_ui/sound_on.svg'

function TitleContainer({
  titleText,
  level,
  message,
  batteryIcon,
  completeTitle,
  coins,
  audioFile,
  playSoundEffect
}) {
  const [isBoldS, setIsBoldS] = useState(false)

  const handleClassChange = (cssClass) => {
    setIsBoldS(cssClass === 'boldS')
  }

  return (
    <div className="information-popup_message-wrapper-out">
      <div
        className={`information-popup_message-wrapper-in ${
          isBoldS ? 'small-padding' : ''
        }`}
      >
        <Title text={titleText} onClassChange={handleClassChange} />

        {level && (
          <div
            className="information-popup_message-text"
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
        )}

        {batteryIcon && (
          <div className="information-popup_message-text_rewards">
            {completeTitle}
          </div>
        )}

        {coins && (
          <div
            className="information-popup_message-text"
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
        )}

        {audioFile && (
          <AudioButton
            className="information-popup_message-audio-button"
            onClick={() => {
              playSoundEffect(audioFile)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default TitleContainer
