import React, { useEffect, useState } from 'react'
import LessonProgressBar from '../LessonProgressBar/LessonProgressBar'
import './LessonItem.scss'

const LessonItem = ({ lesson }) => {
  const BATTERY_BASE_PATH =
    process.env.PUBLIC_URL + '/assets/graphics/batteries/stand-batteries/'

  const ICON_START_URL =
    process.env.PUBLIC_URL + '/assets/graphics/icons/gold-gear.svg'
  const ICON_END_URL = ICON_START_URL

  const [batteryImage, setBatteryImage] = useState(null)
  const [currentPieces, setCurrentPieces] = useState(1)
  const [totalPieces, setTotalPieces] = useState(1)

  useEffect(() => {
    setCurrentPieces(lesson.pieces.have.reduce((a, b) => a + b, 0))
    setTotalPieces(lesson.pieces.pieces.reduce((a, b) => a + b, 0))

    let _batteryImage = 'locked.png'

    if (lesson.is_available) {
      switch (lesson.status) {
        case 'install':
        case 'empty':
          _batteryImage = 'empty.png'
          break

        case 'init':
          _batteryImage = 'low.png'
          break

        case 'charging':
          _batteryImage = 'medium.png'
          break

        case 'complete':
        case 'start':
          _batteryImage = 'full.png'
          break

        case 'broked_start':
          _batteryImage = 'broken.png'
          break
      }
    }

    setBatteryImage(BATTERY_BASE_PATH + _batteryImage)
  }, [])

  return (
    <div className="lesson-item">
      <img className="lesson-item_battery-status" src={batteryImage} />

      <div className="lesson-item_lesson-progress">
        <div className="lesson-item_name">{lesson.lesson_name}</div>
        <LessonProgressBar
          progress={(currentPieces / totalPieces) * 100}
          iconStart={ICON_START_URL}
          text={currentPieces + ' / ' + totalPieces}
          iconEnd={ICON_END_URL}
        />
      </div>
    </div>
  )
}

export default LessonItem
