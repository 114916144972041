import Phaser from 'phaser'
import Bar from './Bar'
import translate from '../../../../i18n/translate'
import { getUnitProgressPercentAndColor } from '../../../../helpers/calculateUnitProgress'

const INFORMATION_DEPTH = 11
const BAR_RADIUS = 7
const BAR_BACK_COLOR = '#939DB0'
const MINIMUN_FONT_SIZE = 8
const BLOCKED_TEXT_COLOR = '#939DB0'

// En el JSON se indica la tile top-left de donde irá el cartel (2x2 tiles)

export default class UnitInformation {
  constructor(
    scene,
    tileSize,
    frameX,
    frameY,
    mapMarginX,
    mapMarginY,
    unitTitle,
    lessonsTotal,
    completeLessonsTotal,
    isAvailable,
    index
  ) {
    this.scene = scene

    this.tileSize = tileSize

    this.fullAreaX = frameX + mapMarginX
    this.fullAreaY = frameY + mapMarginY
    this.mapMarginX = mapMarginX
    this.mapMarginY = mapMarginY
    this.fullAreaWidth = tileSize * 2
    this.fullAreaHeight = tileSize + tileSize / 4

    this.unitTitle = unitTitle

    this.percentAndColor = getUnitProgressPercentAndColor(
      completeLessonsTotal,
      lessonsTotal
    )
    this.isAvailable = isAvailable
    this.index = index

    this.textAreaX = this.fullAreaX
    this.textAreaY = Math.round(this.fullAreaY + this.tileSize * 0.8)
    this.textAreaWidth = Math.round(this.tileSize * 2)
    this.textAreaHeight = Math.round(this.tileSize * 0.9)

    this.unitTitleText = null
    this.unitNumber = null
    this.unitBorder = null
    this.unitProgressBar = null
    this.backStar = null
    this.frontStarBig = null
    this.frontStarSmall = null
  }

  generateInformation() {
    this.drawSign()
    this.drawNumber()
    this.drawName()

    if (this.isAvailable) {
      // Obtener color
      let frontColor = this.percentAndColor.color
      frontColor = Phaser.Display.Color.HexStringToColor(frontColor).color

      this.drawProgressBar(frontColor)
      this.drawBorder(frontColor)
      this.drawDecoration()
    }
  }

  drawSign() {
    if (this.signTop) this.signTop.destroy()

    this.signTop = this.scene.add
      .image(
        this.fullAreaX,
        this.fullAreaY + 1,
        this.isAvailable ? 'sign_top' : 'sign_top_lock',
        null,
        { label: 'sign-top-' + this.unitTitle, isStatic: true }
      )
      .setDisplaySize(this.tileSize * 2, this.tileSize)
      .setOrigin(0)
      .setDepth(INFORMATION_DEPTH)

    if (this.signBottom) this.signBottom.destroy()

    this.signBottom = this.scene.matter.add
      .image(
        this.fullAreaX + this.tileSize,
        this.fullAreaY + this.fullAreaHeight + this.tileSize / 4,
        this.isAvailable ? 'sign_bottom' : 'sign_bottom_lock',
        null,
        { label: 'sign-bottom-' + this.unitTitle, isStatic: true }
      )
      .setDisplaySize(this.tileSize * 2, this.tileSize)
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)
  }

  drawNumber() {
    if (this.unitNumber) this.unitNumber.destroy()

    this.unitNumber = this.scene.add
      .text(
        this.fullAreaX + this.tileSize * 1.02,
        this.fullAreaY + this.tileSize / 4.2,
        this.index + 1,
        {
          fontFamily: 'Quicksand',
          fontStyle: 'bold', // es correcto, pero quicksand no parece tener bold, por lo que no tiene efecto real...
          fontSize: this.tileSize / 4 + 'px',
          color: this.isAvailable ? '#ffffff' : BLOCKED_TEXT_COLOR,
          align: 'center'
        }
      )
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)
  }

  growTextToMaxSize(phaserText, fonSize) {
    const paddingX = Math.round(this.tileSize * 0.2)
    const paddingY = Math.round(this.tileSize * 0.18)
    const maxWidth = Math.round(this.textAreaWidth - paddingX * 2)
    const maxHeight = Math.round(this.textAreaHeight - paddingY * 2)
    let isTooBig = false

    while (!isTooBig) {
      if (phaserText.width > maxWidth || phaserText.height > maxHeight) {
        isTooBig = true
        fonSize -= 0.5
      } else {
        fonSize += 0.5
        phaserText.setFontSize(fonSize + 'px')
      }
    }

    return fonSize
  }

  splitString(inputString, numberOfParts) {
    const words = inputString.split(' ')
    const averageLength = inputString.length / numberOfParts
    const possibleParts = Math.min(words.length, numberOfParts)

    const parts = []
    let part = ''
    let partLength = 0

    words.forEach((word, index) => {
      const nextLength = partLength + word.length + (partLength > 0 ? 1 : 0)

      if (
        Math.abs(averageLength - nextLength) >
          Math.abs(averageLength - partLength - 1) &&
        part.length > 0 &&
        parts.length < possibleParts - 1
      ) {
        parts.push(part)
        part = word
        partLength = word.length
      } else {
        part += (partLength > 0 ? ' ' : '') + word
        partLength += word.length + (partLength > 0 ? 1 : 0)
      }

      if (index === words.length - 1) {
        parts.push(part)
      }
    })

    while (parts.length < numberOfParts) {
      parts.push('')
    }

    return parts.filter((item) => item !== '')
  }

  getMinimunSizeText(text, fontSize, customColor) {
    return this.scene.add
      .text(
        this.textAreaX + this.textAreaWidth / 2,
        this.textAreaY + this.textAreaHeight / 2,
        text,
        {
          fontFamily: 'Quicksand',
          fontStyle: 'bold', // es correcto, pero quicksand no parece tener bold, por lo que no tiene efecto real...
          fontSize: fontSize + 'px',
          color: customColor || '#000000',
          align: 'center'
        }
      )
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)
  }

  drawName() {
    if (this.isAvailable) {
      const isOneLineForced = false

      if (this.unitTitleText) this.unitTitleText.destroy()

      let fontSize1Line = MINIMUN_FONT_SIZE
      const phaserText1Line = this.getMinimunSizeText(
        this.unitTitle,
        fontSize1Line
      )
      fontSize1Line = this.growTextToMaxSize(phaserText1Line, fontSize1Line)

      if (isOneLineForced) {
        this.unitTitleText = phaserText1Line
      } else {
        let fontSize2Lines = MINIMUN_FONT_SIZE
        const text2Lines = this.splitString(this.unitTitle, 2)
        const phaserText2Lines = this.getMinimunSizeText(
          text2Lines,
          fontSize2Lines
        )
        fontSize2Lines = this.growTextToMaxSize(
          phaserText2Lines,
          fontSize2Lines
        )

        let fontSize3Lines = MINIMUN_FONT_SIZE
        const text3Lines = this.splitString(this.unitTitle, 3)
        const phaserText3Lines = this.getMinimunSizeText(
          text3Lines,
          fontSize3Lines
        )
        fontSize3Lines = this.growTextToMaxSize(
          phaserText3Lines,
          fontSize3Lines
        )

        if (
          fontSize3Lines >= fontSize2Lines &&
          fontSize3Lines >= fontSize1Line
        ) {
          phaserText1Line.destroy()
          phaserText2Lines.destroy()
          this.unitTitleText = phaserText3Lines
        } else if (
          fontSize2Lines >= fontSize3Lines &&
          fontSize2Lines >= fontSize1Line
        ) {
          phaserText1Line.destroy()
          phaserText3Lines.destroy()
          this.unitTitleText = phaserText2Lines
        } else {
          phaserText2Lines.destroy()
          phaserText3Lines.destroy()
          this.unitTitleText = phaserText1Line
        }
      }
    } else {
      // Draw blocked text
      this.textAreaY += this.tileSize * 0.16
      this.unitTitleText = this.getMinimunSizeText(
        translate('unit_sign_blocked'),
        this.tileSize * 0.25,
        BLOCKED_TEXT_COLOR
      )
    }
  }

  drawProgressBar(frontColor) {
    if (this.unitProgressBar) this.unitProgressBar.destroy()

    this.unitProgressBar = new Bar(
      this.scene,
      {
        x: this.fullAreaX + this.tileSize * 0.33,
        y: this.fullAreaY + this.tileSize * 0.6,
        width: this.tileSize * 1.36,
        height: this.tileSize * 0.11
      },
      frontColor,
      Phaser.Display.Color.HexStringToColor(BAR_BACK_COLOR).color,
      this.percentAndColor.percent,
      BAR_RADIUS,
      INFORMATION_DEPTH - 1
    )

    this.unitProgressBar.generate()
  }

  drawBorder(frontColor) {
    if (this.isAvailable && this.percentAndColor.percent > 0) {
      if (this.unitBorder) this.unitBorder.destroy()

      const borderSize = Math.max(Math.round(this.tileSize * 0.075), 1)
      this.unitBorder = this.scene.add.graphics().setDepth(INFORMATION_DEPTH)
      this.unitBorder.lineStyle(borderSize, frontColor)
      this.unitBorder.strokeRoundedRect(
        this.textAreaX + 3,
        this.textAreaY + 3,
        this.textAreaWidth - 6,
        this.textAreaHeight - 6,
        2
      )
    }
  }

  drawDecoration() {
    if (this.percentAndColor.percent >= 100) {
      if (this.backStar) this.unitBorder.destroy()

      this.backStar = this.scene.matter.add
        .image(
          this.fullAreaX + this.tileSize,
          this.fullAreaY + this.tileSize * 0.65,
          'sign_star_back',
          null,
          { label: 'sign_star_back-' + this.unitTitle, isStatic: true }
        )
        .setDisplaySize(this.tileSize * 2, this.tileSize * 2)
        .setOrigin(0.5)
        .setDepth(INFORMATION_DEPTH - 2)

      if (this.frontStarBig) this.frontStarBig.destroy()

      this.frontStarBig = this.scene.matter.add
        .image(
          this.fullAreaX + this.tileSize * 0.13,
          this.fullAreaY + this.tileSize * 0.74,
          'sign_star_front_big',
          null,
          { label: 'sign_star_front_big-' + this.unitTitle, isStatic: true }
        )
        .setDisplaySize(this.tileSize * 0.35, this.tileSize * 0.35)
        .setOrigin(0.5)
        .setDepth(INFORMATION_DEPTH)

      if (this.frontStarSmall) this.frontStarSmall.destroy()

      this.frontStarSmall = this.scene.matter.add
        .image(
          this.fullAreaX + this.tileSize * 1.8,
          this.fullAreaY + this.tileSize * 1.67,
          'sign_star_front_small',
          null,
          { label: 'sign_star_front_small-' + this.unitTitle, isStatic: true }
        )
        .setDisplaySize(this.tileSize * 0.21, this.tileSize * 0.21)
        .setOrigin(0.5)
        .setDepth(INFORMATION_DEPTH)
    }
  }

  updateMapMargins(mapMarginX, mapMarginY) {
    try {
      const modX = this.mapMarginX - mapMarginX
      const modY = this.mapMarginY - mapMarginY

      if (this.signTop) {
        this.signTop.x -= modX
        this.signTop.y -= modY
      }

      if (this.signBottom) {
        this.signBottom.x -= modX
        this.signBottom.y -= modY
      }

      if (this.unitTitleText) {
        this.unitTitleText.x -= modX
        this.unitTitleText.y -= modY
      }

      if (this.unitProgressBar) {
        const backBar = this.unitProgressBar.backgroundBar
        backBar.x -= modX
        backBar.y -= modY

        const foreBar = this.unitProgressBar.foregroundBar
        if (foreBar) {
          foreBar.x -= modX
          foreBar.y -= modY
        }
      }

      if (this.unitNumber) {
        this.unitNumber.x -= modX
        this.unitNumber.y -= modY
      }

      if (this.unitBorder) {
        this.unitBorder.x -= modX
        this.unitBorder.y -= modY
      }

      if (this.backStar) {
        this.backStar.x -= modX
        this.backStar.y -= modY
      }
      if (this.frontStarBig) {
        this.frontStarBig.x -= modX
        this.frontStarBig.y -= modY
      }
      if (this.frontStarSmall) {
        this.frontStarSmall.x -= modX
        this.frontStarSmall.y -= modY
      }

      this.mapMarginX = mapMarginX
      this.mapMarginY = mapMarginY
    } catch (updateMapMarginsError) {
      console.error('UnitInformation updateMapMargins', updateMapMarginsError)
    }
  }

  destroy() {
    if (this.unitTitleText) this.unitTitleText.destroy()
    if (this.unitProgressBar) this.unitProgressBar.destroy()
    if (this.unitNumber) this.unitNumber.destroy()
    if (this.unitBorder) this.unitBorder.destroy()
    if (this.signTop) this.signTop.destroy()
    if (this.signBottom) this.signBottom.destroy()
    if (this.backStar) this.unitBorder.destroy()
    if (this.frontStarBig) this.frontStarBig.destroy()
    if (this.frontStarSmall) this.frontStarSmall.destroy()
  }
}
