import posthog from 'posthog-js'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import { updateIsFlagEnabledGamification } from '../store/Configuration/Configuration'
import isFeatureEnabled, { FEATURE_FLAGS } from './featureFlagService'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

export default function usePostHogService() {
  const ph = usePostHog()
  const dispatch = useDispatch()
  const [isPostHogInitialized, setIsPostHogInitialized] = useState(false)

  const initializePostHog = (whoAmI) => {
    console.log('initializePostHog whoAmI -- ', whoAmI)
    if (!isPostHogInitialized) {
      console.log('initializePostHog init -- ')
      posthog.init(
        window.isProductionEnvironment
          ? 'phc_3uRym0rwkVA7zbXCz5efpgyzXNdtdU7j3JqgFSQb2ag'
          : 'phc_50dWocuhiGdo6M0t2pAl5Pwh7SzGFinvVJRZuPx0RsN',
        {
          api_host: 'https://app.posthog.com',
          autocapture: !whoAmI?.is_demo,
          disable_session_recording:
            window.isProductionEnvironment && Boolean(whoAmI?.is_demo),
          person_profiles: 'identified_only'
        }
      )

      setIsPostHogInitialized(true)
    }

    ph.identify(whoAmI?.guid, whoAmI)
    dispatch(
      updateIsFlagEnabledGamification(
        isFeatureEnabled(FEATURE_FLAGS.ENABLE_GAMIFICATION_ENGINE)
      )
    )
  }

  return { ph, initializePostHog }
}

function PostHogServiceProvider(props) {
  return <PostHogProvider client={posthog} {...props} />
}

function resetPosthog() {
  posthog.reset()
}

export { PostHogServiceProvider, resetPosthog }
