import { useState, useEffect } from 'react'
import CONNECTION_STATUS from '../config/constants/connectionTypes'

const useInternetConnection = () => {
  const [connectionStatus, setConnectionStatus] = useState(
    CONNECTION_STATUS.ONLINE
  )

  useEffect(() => {
    const handleOnline = () => setConnectionStatus(CONNECTION_STATUS.ONLINE)
    const handleOffline = () => setConnectionStatus(CONNECTION_STATUS.OFFLINE)
    const checkConnection = () => {
      if (navigator.onLine) {
        // Verificar la velocidad de conexión
        const connection =
          navigator.connection ||
          navigator.mozConnection ||
          navigator.webkitConnection
        if (connection && connection.downlink < 1) {
          setConnectionStatus(CONNECTION_STATUS.SLOW)
        } else {
          setConnectionStatus(CONNECTION_STATUS.ONLINE)
        }
      } else {
        setConnectionStatus(CONNECTION_STATUS.OFFLINE)
      }
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    window.addEventListener('load', checkConnection)

    // Verificar la conexión periódicamente
    const intervalId = setInterval(checkConnection, 5000)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('load', checkConnection)
      clearInterval(intervalId)
    }
  }, [])

  return connectionStatus
}

export default useInternetConnection
