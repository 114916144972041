import React, { useEffect, useState } from 'react'
import translate from '../../../../i18n/translate'
import BlueberryFamilyPanel from '../../components/BlueberryFamilyPanel/BlueberryFamilyPanel'
import FormButton from '../../components/FormButton/FormButton'
import FormInput from '../../components/FormInput/FormInput'
import FormSelect from '../../components/FormSelect/FormSelect'
import FormPopup from '../../components/FormPopup/FormPopup'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash.svg'
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg'
import {
  createCourse,
  createPlayer,
  linkCourseWithPlayer,
  removePlayer
} from '../../../../services/userManagementService'
import { Analysis } from '../../../../services/analysisService'
import {
  getSourceInfoParam,
  getSourceMetadataParams
} from '../../../../services/sourceService'

const ProfilesForm = ({ onCancel, onNext, licenseData, userData }) => {
  const MAX_PROFILES = 4
  const MIN_NAME_CHARACTERS = 2

  const ERROR_MESSAGES = {
    NAME_EMPTY: translate('family_signup_profiles_error_name_empty'),
    NAME_CHARACTERS: translate('family_signup_profiles_error_name_characters'),
    COURSE_EMPTY: translate('family_signup_profiles_error_course_empty'),
    MAXED_PROFILES: translate('family_signup_profiles_error_max_profiles'),
    SAVE_ONE_PROFILE: translate(
      'family_signup_profiles_error_save_one_profile'
    ),
    SAVE_LAST_PROFILE: translate(
      'family_signup_profiles_error_save_last_profile'
    )
  }

  const EMPTY_PROFILE = {
    name: '',
    nameErrorMessage: '',
    course: null,
    courseErrorMessage: '',
    isSaved: false
  }

  const [profileList, setProfileList] = useState([EMPTY_PROFILE])
  const [showAddButton, setShowAddButton] = useState(false)
  const [indexToDelete, setIndexToDelete] = useState(null)
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] =
    useState(false)
  const [licenseCourses, setLicenseCourses] = useState([])
  const [userCourses, setUserCourses] = useState([])
  const [isLastSaved, setIsLastSaved] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')

  let savingTimeout = null

  useEffect(() => {
    const programs = licenseData.data.programs
    const courses = []
    for (const p in programs) {
      const programData = programs[p]
      const courseName =
        programData.education_year.name + ' ' + programData.education_level.name

      courses.push({
        value: p,
        label: courseName,
        education_level: programData.education_level,
        education_year: programData.education_year,
        discipline: programData.discipline,
        programGuid: programData.program.guid
      })
    }
    // ocLog('courses', courses)
    setLicenseCourses(courses)

    // Para evento
    const sourceMetadata = JSON.parse(getSourceMetadataParams())
    const metadataDeconstruction = {}
    Object.keys(sourceMetadata).forEach((key) => {
      metadataDeconstruction['analytics_metadata_' + key] = sourceMetadata[key]
    })

    Analysis.sendEvent(Analysis.EVENT.SIGN_UP_ADD_PLAYER_SCREEN, {
      sourceOfUser: getSourceInfoParam(),
      ...metadataDeconstruction
    })

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Signup,
      Analysis.SEGMENT_EVENTS['Signup Page Add Players Viewed'],
      {
        // ...metadataDeconstruction,
        source_of_user: getSourceInfoParam(),
        user_id: userData?.signup?.guid
      }
    )
  }, [])

  // useEffect(() => {
  //   ocLog('PROFILE LIST >>>', profileList)
  // }, [profileList])

  const lastProfile = profileList[profileList.length - 1]

  const isDisabledSaveButton =
    lastProfile.name === '' ||
    lastProfile.course === null ||
    lastProfile.nameErrorMessage !== '' ||
    lastProfile.courseErrorMessage !== ''

  const validateProfileName = (index) => {
    const _list = [...profileList]

    if (_list[index].name.length === 0) {
      _list[index].nameErrorMessage = ERROR_MESSAGES.NAME_EMPTY
    } else {
      if (_list[index].name.length < MIN_NAME_CHARACTERS) {
        _list[index].nameErrorMessage = ERROR_MESSAGES.NAME_CHARACTERS
      } else {
        _list[index].nameErrorMessage = ''
      }
    }

    setProfileList(_list)
  }

  const validateProfileCourse = (index) => {
    const _list = [...profileList]

    if (_list[index].course === null || _list[index].course?.length === 0) {
      _list[index].courseErrorMessage = ERROR_MESSAGES.COURSE_EMPTY
    } else {
      _list[index].courseErrorMessage = ''
    }

    setProfileList(_list)
  }

  const hasProfileErrors = () => {
    validateProfileName(profileList.length - 1)
    validateProfileCourse(profileList.length - 1)
    return !!(
      lastProfile.nameErrorMessage !== '' ||
      lastProfile.courseErrorMessage !== ''
    )
  }

  const saveProfile = async () => {
    setIsWorking(true)
    clearTimeout(savingTimeout)

    savingTimeout = setTimeout(async () => {
      if (!hasProfileErrors() && !isLastSaved) {
        const currentProfile = profileList[profileList.length - 1]
        let course = userCourses[parseInt(currentProfile.course.value)]

        // Si no existe el curso para el usuario se crea
        if (!course) {
          const selectedCourse =
            licenseCourses[parseInt(currentProfile.course.value)]

          const courseSettings = {
            name: selectedCourse.label,
            education_year_guid: selectedCourse.education_year.guid,
            education_discipline_guid: selectedCourse.discipline.guid,
            programs: [selectedCourse.programGuid]
          }

          course = await createCourse(courseSettings)
          // ocLog('courseResponse', course)

          setUserCourses([...userCourses, course])
        }

        // Crear usuario
        const userSettings = {
          name: currentProfile.name,
          role: 'R01',
          username: userData.signup.guid + '.' + Date.now()
        }
        const userResponse = await createPlayer(userSettings)
        // ocLog('userResponse', userResponse)

        // Vincular usuario y curso
        // const linkCourseResponse = await linkCourseWithPlayer(
        await linkCourseWithPlayer(course.guid, userResponse.guid)
        // ocLog('linkCourseResponse', linkCourseResponse)

        Analysis.sendEvent(Analysis.EVENT.ADD_PLAYER, {
          current_total_players: profileList.length
        })

        // TODO: mostrar error en pantalla y confirmación de guardado
        profileList[profileList.length - 1].isSaved = true
        profileList[profileList.length - 1].playerGuid = userResponse.guid
        profileList[profileList.length - 1].courseGuid = course.guid
        setShowAddButton(true)

        if (profileList.length === MAX_PROFILES) {
          setIsLastSaved(true)
        }

        setIsWorking(false)
      } else {
        setIsWorking(false)
      }
    }, 250)

    setFormErrorMessage('')
  }

  const addProfile = () => {
    if (profileList.length === MAX_PROFILES) {
      setFormErrorMessage(ERROR_MESSAGES.MAXED_PROFILES)
    } else {
      if (!hasProfileErrors()) {
        setProfileList([...profileList, EMPTY_PROFILE])
        setShowAddButton(false)
      }
    }
  }

  const handleDeleteProfile = (index) => {
    setIndexToDelete(index)
    setShowDeleteConfirmationPopup(true)
  }

  const deleteProfile = async (index) => {
    setShowDeleteConfirmationPopup(false)
    const profile = profileList[index]

    // si no hemos guardado el perfil, no mandamos la request
    if (profileList[index].isSaved) {
      const removeResponse = await removePlayer(
        profile.courseGuid,
        profile.playerGuid
      )

      if (removeResponse?.status === 'fail') return
      // TODO: mostrar error en pantalla

      Analysis.sendEvent(Analysis.EVENT.REMOVE_PLAYER, {
        current_total_players: profileList.length - 1
      })
    }

    const _list = [...profileList]
    if (profileList.length > 1) {
      _list.splice(index, 1)
      setProfileList(_list)
      setShowAddButton(true)
    } else {
      setProfileList([EMPTY_PROFILE])
      setShowAddButton(false)
    }

    setIsLastSaved(false)
    setFormErrorMessage('')
  }

  const onChangeName = (e, index) => {
    const { id, value } = e.target
    const _list = [...profileList]
    _list[index][id] = value
    setProfileList(_list)
    validateProfileName(index)
  }

  const onChangeCourse = (value, index, id) => {
    const _list = [...profileList]
    _list[index][id] = value
    setProfileList(_list)
    validateProfileCourse(index)
  }

  const handleGoNext = () => {
    validateProfileName(profileList.length - 1)
    validateProfileCourse(profileList.length - 1)
    if (lastProfile.isSaved !== true) {
      if (profileList.length === 1) {
        setFormErrorMessage(ERROR_MESSAGES.SAVE_ONE_PROFILE)
      } else {
        setFormErrorMessage(ERROR_MESSAGES.SAVE_LAST_PROFILE)
      }
    } else {
      if (!hasProfileErrors()) {
        setFormErrorMessage('')
        onNext()
      }
    }
  }

  return (
    <div className="profiles-form">
      <BlueberryFamilyPanel>
        <div className="blueberry-family-panel__title">
          {translate('family_signup_profiles_title')}
        </div>
        <div className="blueberry-family-panel__subtitle">
          {translate('family_signup_profiles_subtitle')}
        </div>

        {licenseCourses && (
          <form autoComplete="off">
            <div className="profiles-form__profile-list">
              {profileList.map((singleProfile, index) => (
                <div
                  className="profiles-form__single-profile"
                  key={
                    singleProfile.playerGuid ? singleProfile.playerGuid : index
                  }
                >
                  <FormInput
                    id="name"
                    value={singleProfile.name}
                    errorMessage={singleProfile.nameErrorMessage}
                    onChange={(e) => onChangeName(e, index)}
                    inputType="text"
                    placeholder={translate(
                      'family_signup_profiles_name_placeholder'
                    )}
                    handleChangeOutside
                    disabled={singleProfile.isSaved}
                  />

                  <FormSelect
                    id="course"
                    value={singleProfile.course}
                    options={licenseCourses}
                    errorMessage={singleProfile.courseErrorMessage}
                    onChange={(value) => onChangeCourse(value, index, 'course')}
                    placeholder={translate(
                      'family_signup_profiles_course_placeholder'
                    )}
                    handleChangeOutside
                    disabled={singleProfile.isSaved}
                  />

                  {lastProfile.isSaved && profileList.length > 1 && (
                    <>
                      <FormButton
                        variant="transparent"
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteProfile(index)}
                        disabled={index === 0 && singleProfile.name === ''}
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="profiles-form__actions">
              {showAddButton && (
                <FormButton
                  variant="transparent"
                  icon={<PlusIcon />}
                  text={translate('family_signup_profiles_add_profile')}
                  textAlign="left"
                  onClick={addProfile}
                />
              )}
            </div>
            {formErrorMessage && (
              <div className="profiles-form__error">{formErrorMessage}</div>
            )}
          </form>
        )}

        <div className="blueberry-family-panel__actions blueberry-family-panel__actions--row">
          {/* Jugadores sin guardar */}
          {!lastProfile.isSaved &&
            (profileList.length === 1 ? (
              <FormButton
                text={translate('family_signup_profiles_save_profile')}
                textAlign="left"
                onClick={saveProfile}
                disabled={isWorking || isDisabledSaveButton}
              />
            ) : (
              <>
                <FormButton
                  variant="transparent"
                  text={translate('family_signup_cancel')}
                  onClick={() => deleteProfile(profileList.length - 1)}
                />
                <FormButton
                  text={translate('family_signup_profiles_save_profile')}
                  textAlign="left"
                  onClick={saveProfile}
                  disabled={isWorking}
                />
              </>
            ))}

          {/* Jugadores guardados */}
          {lastProfile.isSaved && (
            <FormButton
              text={translate('family_signup_play')}
              onClick={handleGoNext}
              disabled={isWorking}
            />
          )}
        </div>
      </BlueberryFamilyPanel>

      {showDeleteConfirmationPopup && (
        <FormPopup
          aditionalClass="profiles-form__delete-popup"
          size="sm"
          title={translate('family_signup_profiles_delete_popup_title', {
            name: profileList[indexToDelete].name
          })}
          subtitle={translate('family_signup_profiles_delete_popup_subtitle')}
          actions={
            <>
              <FormButton
                text={translate('family_signup_delete')}
                onClick={() => deleteProfile(indexToDelete)}
              />
              <FormButton
                variant="transparent"
                text={translate('family_signup_cancel')}
                onClick={() => setShowDeleteConfirmationPopup(false)}
              />
            </>
          }
        ></FormPopup>
      )}
    </div>
  )
}

export default ProfilesForm
