import React from 'react'
import './AvatarData.scss'
const AvatarData = ({
  title,
  description,
  showMainButton,
  textMainButton,
  isIntro,
  handleMainButton
}) => {
  return (
    <div className="avatar-data-container">
      <div className="avatar-data-container__title">{title}</div>
      {showMainButton && (
        <div
          className={`meta-popup-selector__button-container ${
            isIntro && 'meta-popup-selector__button-container-intro'
          }`}
        >
          <div
            className="meta-popup-selector__button"
            onClick={handleMainButton}
            data-testid="avatar_data_button"
          >
            {textMainButton}
          </div>
        </div>
      )}
      <div className="avatar-data-container__description">
        <div className="avatar-data-container__description-text">
          {description}
        </div>
      </div>
    </div>
  )
}

export default AvatarData
