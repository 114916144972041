import React, { useState, useEffect } from 'react'
import { Analysis } from '../../../services/analysisService'
import { useSelector } from 'react-redux'
import translate from '../../../i18n/translate'
import missionsImg from '../../../assets/img/metaberry_ui/map_menu_missions.png'
import petsImg from '../../../assets/img/metaberry_ui/map_menu_pets.png'
import birdeyeImg from '../../../assets/img/metaberry_ui/map_menu_birdeye.png'
import progressImg from '../../../assets/img/metaberry_ui/progress-img.png'
import './mapBottomBar.scss'

import { customStorage } from '../../../util/customStorage'
import { getWhoAmI } from '../../../services/courseInfoService'
import { store } from '../../../store/configureStore'

const MapBottomBar = ({
  handleOpenMissionsPanel,
  handleGoToPetsSelector,
  handleCourseProgress,
  pathSchool,
  isFlagEnabledGamification,
  isReadyGamification
}) => {
  const [isBarVisible, setIsBarVisible] = useState(true)
  const [petsNotificationActive, setPetsNotificationActive] = useState(false)
  const [missionsNotificationActive, setMissionsNotificationActive] =
    useState(false)
  const [showCourseProgressAlert, setShowCourseProgressAlert] = useState(false)

  const petsNotificationState = useSelector(
    (state) => state.rewards.petsNotification
  )

  const missionsDailyRewardActive = useSelector((state) =>
    isFlagEnabledGamification
      ? state.rewards.canGetDailyReward
      : state.dailyGoal.canGetDailyReward
  )

  const handleCourseProgressAux = async () => {
    customStorage.set('bb_progress-visited', 1)
    setShowCourseProgressAlert(false)
    handleCourseProgress()

    const userWho = await getWhoAmI()
    const units = store.getState().units

    let unitsComplete = 0
    for (let u = 0; u < units.length; u++) {
      if (units[u].complete_lessons_total === units[u].lessons_total) {
        unitsComplete++
      }
    }

    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Progress Map Clicked'],
      {
        username: userWho.username,
        program_id: store.getState()?.course?.data?.program_guid,
        number_units_completed: unitsComplete
      }
    )
  }

  // Birdeye
  const handleToggleBirdeye = () => {
    const birdeyeEvent = new CustomEvent('toggle-birdeye')
    window.dispatchEvent(birdeyeEvent)
  }

  // Hide/Show menu
  const toggleBarVisibility = () => {
    setIsBarVisible(!isBarVisible)
  }

  const ChevronIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9314 12.0974C5.63298 11.799 5.63298 11.3151 5.9314 11.0167L8.44766 8.50044L5.9314 5.98417C5.63298 5.68576 5.63298 5.20192 5.9314 4.9035C6.22981 4.60508 6.71365 4.60508 7.01207 4.9035L10.0687 7.96011C10.3671 8.25853 10.3671 8.74236 10.0687 9.04078L7.01207 12.0974C6.71365 12.3958 6.22982 12.3958 5.9314 12.0974Z"
        fill="white"
      />
    </svg>
  )

  useEffect(() => {
    const courseProgressShowed = customStorage.get('bb_progress-visited')
    if (!courseProgressShowed) {
      setShowCourseProgressAlert(true)
    }
  }, [])

  useEffect(() => {
    if (petsNotificationState) {
      setPetsNotificationActive(true)
    } else {
      setPetsNotificationActive(false)
    }
  }, [petsNotificationState])

  useEffect(() => {
    if (missionsDailyRewardActive) {
      setMissionsNotificationActive(true)

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Daily Reward Available']
      )
    } else {
      setMissionsNotificationActive(false)
    }
  }, [missionsDailyRewardActive])

  useEffect(() => {
    if (missionsNotificationActive) {
      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Missions Notification Showed']
      )
    }
  }, [missionsNotificationActive])

  return (
    <div
      className={`map-bottom-bar ${
        isBarVisible
          ? 'map-bottom-bar--visible'
          : isFlagEnabledGamification && !isReadyGamification
          ? 'map-bottom-bar--hidden-gam'
          : 'map-bottom-bar--hidden'
      }`}
    >
      {((!pathSchool && petsNotificationActive) ||
        missionsNotificationActive) &&
        !isBarVisible &&
        (isFlagEnabledGamification ? isReadyGamification : true) && (
          <div className="map-bottom-bar__missions-button-exclamation map-bottom-bar__missions-button-exclamation--grouped">
            !
          </div>
        )}

      <div className="map-bottom__button" onClick={toggleBarVisibility}>
        <ChevronIcon />
      </div>
      <div className="map-bottom-bar__wrapper">
        {(!isFlagEnabledGamification ||
          (isFlagEnabledGamification && isReadyGamification)) && (
          <>
            <div
              className="map-bottom-bar__missions-button"
              onClick={handleOpenMissionsPanel}
              onKeyUp={() => {}}
            >
              {missionsNotificationActive && (
                <>
                  <div className="map-bottom-bar__missions-tooltip">
                    {translate('info_tooltip_new_mission')}
                  </div>
                  <div className="map-bottom-bar__missions-button-halo"></div>
                  <div className="map-bottom-bar__missions-button-exclamation">
                    !
                  </div>
                </>
              )}
              <img src={missionsImg} alt="" />
            </div>

            <div
              className="map-bottom-bar__compas-button"
              onClick={handleCourseProgressAux}
              onKeyUp={() => {}}
            >
              {showCourseProgressAlert && (
                <>
                  <div className="map-bottom-bar__missions-tooltip">
                    {translate('info_tooltip_new_progress')}
                  </div>
                  <div className="map-bottom-bar__missions-button-halo"></div>
                  <div className="map-bottom-bar__missions-button-exclamation">
                    !
                  </div>
                </>
              )}
              <img
                className="map-bottom-bar__compas-image"
                src={progressImg}
                alt=""
              />
            </div>

            <div
              className="map-bottom-bar__pets-button"
              onClick={handleGoToPetsSelector}
              onKeyUp={() => {}}
            >
              {petsNotificationActive && (
                <>
                  <div className="map-bottom-bar__pets-tooltip">
                    {translate('info_tooltip_new_pets')}
                  </div>
                  <div className="map-bottom-bar__pets-button-halo"></div>
                  <div className="map-bottom-bar__pets-button-exclamation">
                    !
                  </div>
                </>
              )}
              <img src={petsImg} alt="" />
            </div>
          </>
        )}

        <div className="map-bottom-bar__birdeye-button">
          <img
            src={birdeyeImg}
            alt=""
            onClick={handleToggleBirdeye}
            onKeyUp={() => {}}
          />
        </div>
      </div>
    </div>
  )
}

export default MapBottomBar
