import React from 'react'
import './CloseButton.scss'

const CloseButton = ({ onClick }) => {
  return (
    <button className="close-button" onClick={onClick} aria-label="Close">
      <span>✖</span>
    </button>
  )
}

export default CloseButton
