import { getCourseGuid } from '../../../../assets/data/api'
import { getCurrentLangString } from '../../../../i18n/initI18n'
import { fetchWithToken, createEndPoint } from '../../../../services/util'

const getLessons = async (unitGuid) => {
  ocLog('tms lessons')
  const courseGuid = await getCourseGuid()

  const currentLang = getCurrentLangString()

  const currentCustomLanguage =
    window.localStorage.getItem('bb_custom_language') || currentLang

  // En iPad parece fallar por cacheo de llamada
  const iPadFix =
    '?tmstp=' + new Date().getTime() + '&lang=' + currentCustomLanguage
  const lessonsEndPoint = `lms/courses/${courseGuid}/blueberry/units/${unitGuid}/lessons${iPadFix}`

  return fetchWithToken(createEndPoint(lessonsEndPoint)).then((json) =>
    json.data.sort((a, b) => a.order - b.order)
  )
}

export default getLessons
