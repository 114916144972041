import React from 'react'
import { useActivityHeader } from './useActivityHeader'
import './activityHeader.scss'
import SvgLihtningBolt from '../../../assets/icons/lightning-bolt-blue.svg'
import PracticeButton from '../../atoms/PracticeButton/PracticeButton'
import { ReactComponent as SvgChevronLeft } from '../../../assets/icons/chevron-left.svg'
import { ReactComponent as SvgIssue } from '../../../assets/icons/flag.svg'
import MapItemsCounter from '../../../../../modules/components/MapItemsCounter/MapItemsCounter'
import { ReactComponent as CoinSvg } from '../../../../../assets/img/metaberry_ui/coin.svg'
import { ReactComponent as PencilSvg } from '../../../../../assets/img/metaberry_ui/pencil.svg'
import translate from '../../../../../i18n/translate'
import MapTabsTooltip from '../../../../../modules/components/MapTabsTooltip/MapTabsTooltip'

const ActivityHeader = ({
  messageToReactNative,
  barMax,
  barCurrent,
  isPractice,
  setShowAbortPracticePopup,
  currentExercise,
  previousSeedId,
  numberExercisesCorrect,
  numberExercisesIncorrect,
  practiceStartedDate,
  setShowIssueReportPopup,
  isFromApp,
  isDifficultActivity,
  showPenAndPaperBadget,
  difficultActivityAnswered,
  practiceBaseData,
  pathSchool,
  practiceProgressData,
  isFlagEnabledGamification
}) => {
  const {
    practiceCoinsAccumulated,
    lessonCurrentPieces,
    lessonTotalPieces,
    forcedCloseExecise,
    getBarPercentage,
    openIssueReportPopup,
    toogleDevMode
  } = useActivityHeader({
    currentExercise,
    previousSeedId,
    numberExercisesCorrect,
    numberExercisesIncorrect,
    practiceStartedDate,
    barCurrent,
    barMax,
    messageToReactNative,
    setShowAbortPracticePopup,
    setShowIssueReportPopup,
    isFromApp,
    difficultActivityAnswered,
    practiceBaseData,
    practiceProgressData,
    isFlagEnabledGamification
  })

  return (
    <div className="activity-header">
      <div className="activity-header__first-line">
        {/* Botón salir */}
        <PracticeButton
          icon={<SvgChevronLeft />}
          type={3}
          action={forcedCloseExecise}
          disabled={!isPractice}
        />

        {/* Barra de experiencia */}
        <div className="activity-header__information">
          <img
            src={SvgLihtningBolt}
            alt="⭍"
            draggable="false"
            onClick={toogleDevMode}
          />
          <div className="activity-header__bar-container">
            <div
              className="activity-header__bar-content"
              style={{ width: getBarPercentage() }}
            >
              {isDifficultActivity &&
                (difficultActivityAnswered !== true ? (
                  <CoinSvg />
                ) : (
                  <CoinSvg className="activity-header__bar-coin-correct-animation" />
                ))}
            </div>
          </div>
          <div className="activity-header__gears">
            <MapItemsCounter
              variant="gear"
              count={lessonCurrentPieces}
              total={lessonTotalPieces}
              practiceVersion={true}
              arrowPosition={window.innerWidth > 768 ? 'left' : 'right'}
            />
          </div>
          <div className="activity-header__coins">
            <MapItemsCounter
              variant="coin"
              count={practiceCoinsAccumulated}
              practiceVersion={true}
              arrowPosition={'right'}
              animationDelay={1400}
              pathSchool={pathSchool}
            />
          </div>
        </div>

        {/* Botón reportar errores */}
        {!isFromApp && (
          <div className="activity-header__issue-report">
            <PracticeButton
              icon={<SvgIssue />}
              type={3}
              action={openIssueReportPopup}
            />
          </div>
        )}
      </div>

      {showPenAndPaperBadget && (
        <div className="activity-header__difficult-activity-badget-wrapper">
          <div className="activity-header__difficult-activity-badget">
            <MapTabsTooltip
              width={window.innerWidth < 768 ? '160px' : '190px'}
              arrowPosition={'center'}
              message={translate('difficult_activity_badget_tooltip')}
              isDisabled={true}
            >
              <PencilSvg />

              <span>{translate('difficult_activity_badget_message')}</span>
            </MapTabsTooltip>
          </div>
        </div>
      )}
    </div>
  )
}

export default ActivityHeader
