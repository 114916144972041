import React, { useEffect, useState } from 'react'
import { ReactComponent as SvgLoading } from './loading.svg'
import './practiceButton.scss'

/*
  text         -> string         Texto del botón (puede tener icono también)
  icon         -> ReactComponent Icono del botón (puede tener texto también)
  action       -> function       Acción a realizar
  disabled     -> boolean        Deshabilita el botón  
  type         -> integer        Tipo de botón: (0, 1) "primario", 2 "secundario" o 3 "terciario". Por defecto "primario"
  swapIconText -> boolean        Intercambia orden del icono y texto
*/
const PracticeButton = ({
  text,
  icon,
  action,
  disabled,
  isLoading,
  type,
  keepActive,
  swapIconText,
  doNotStopPropatation
}) => {
  const [clicks, setClicks] = useState(0)
  const [debouncedClicks, setDebouncedClicks] = useState(0)
  const [active, setActive] = useState(false)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedClicks(clicks)
    }, 300)

    return () => {
      clearTimeout(timerId)
    }
  }, [clicks])

  useEffect(() => {
    if (debouncedClicks > 0) {
      handleClick()
    }
  }, [debouncedClicks])

  const handleClick = () => {
    if (!disabled) {
      if (keepActive) {
        setActive(!active)
      }

      if (action) {
        action()
      }
    }
  }

  const clickOnButton = (event) => {
    !doNotStopPropatation && event.stopPropagation()
    setClicks(clicks + 1)
  }

  return (
    <div
      className={
        'practice-button' +
        (disabled ? ' disabled' : '') +
        (!disabled ? ' type-' + type : '') +
        (swapIconText ? ' swap-icon-text' : '') +
        (active ? ' active' : '') +
        (!text ? ' icon-only' : '')
      }
      onClick={clickOnButton}
      data-testid="practice_button"
    >
      {isLoading ? <SvgLoading className="practice-button_loading" /> : icon}
      {text && <span>{text}</span>}
    </div>
  )
}

export default PracticeButton
