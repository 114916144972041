import { getCourseGuid } from '../assets/data/api'
import { createEndPoint, fetcher, getToken } from './util'

// const unitOrder = 2
const only_truncate = 0

export async function resetDBEndPoint(unitOrder) {
  // ocLog('Resetting unitOrder', unitOrder)
  const courseGuid = await getCourseGuid()
  const endPoint = `lms/courses/${courseGuid}/blueberry/demo `
  return fetcher(createEndPoint(endPoint), {
    method: 'PUT',
    body: JSON.stringify({ unitOrder, only_truncate }),
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    }
  }).then((json) => json.data)
}
