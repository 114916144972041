import { useEffect, useState } from 'react'
import {
  getLicensePrograms,
  getPlayersList,
  createCourse,
  createPlayer,
  linkCourseWithPlayer,
  removePlayer,
  updatePlayerName
} from '../../../../services/userManagementService'
import { getWhoAmI } from '../../../../services/courseInfoService'
import {
  setFamilyUserData,
  getFamilyUserData
} from '../../../../services/userTypeService'
import translate from '../../../../i18n/translate'
import { Analysis } from '../../../../services/analysisService'
import { getToken } from '../../../../services/util'
import { removeStoredCourses } from '../../../../assets/data/api'

export default function useMyProfilePlayers() {
  const MAX_PROFILES = 4
  const MIN_NAME_CHARACTERS = 2

  const ERROR_MESSAGES = {
    NAME_EMPTY: translate('family_signup_profiles_error_name_empty'),
    NAME_CHARACTERS: translate('family_signup_profiles_error_name_characters'),
    COURSE_EMPTY: translate('family_signup_profiles_error_course_empty'),
    MAXED_PROFILES: translate('family_signup_profiles_error_max_profiles')
  }

  const EMPTY_PROFILE = {
    name: '',
    nameErrorMessage: '',
    course: null,
    courseErrorMessage: '',
    isSaved: false,
    courseGuid: null,
    playerGuid: null
  }

  // const MOCK_PROFILE_LIST = [
  //   {
  //     name: 'Paco',
  //     nameErrorMessage: '',
  //     course: { value: 'c1', label: 'Curso 1' },
  //     courseErrorMessage: '',
  //     isSaved: true,
  //     courseGuid: null,
  //     playerGuid: null
  //   },
  //   {
  //     name: 'Pepa',
  //     nameErrorMessage: '',
  //     course: { value: 'c1', label: 'Curso 1' },
  //     courseErrorMessage: '',
  //     isSaved: true,
  //     courseGuid: null,
  //     playerGuid: null
  //   }
  // ]

  const [showAddButton, setShowAddButton] = useState(true)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  const [licensePrograms, setLicensePrograms] = useState([])
  const [licenseCourseOptions, setLicenseCourseOptions] = useState([])
  const [initialProfileList, setInitialProfileList] = useState([EMPTY_PROFILE])
  const [isProfilesDataLoaded, setIsProfilesDataLoaded] = useState(false)
  const [profileList, setProfileList] = useState([EMPTY_PROFILE])

  const [isLastSaved, setIsLastSaved] = useState(false)
  const [userCourses, setUserCourses] = useState([])
  const [isWorking, setIsWorking] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [isCurrentPlayerDeleted, setIsCurrentPlayerDeleted] = useState(false)

  let savingTimeout = null

  const isDisabledButton =
    profileList[profileList.length - 1].name === '' ||
    profileList[profileList.length - 1].course === null ||
    profileList[profileList.length - 1].nameErrorMessage !== '' ||
    profileList[profileList.length - 1].courseErrorMessage !== ''

  const validateProfileName = (index) => {
    const _list = [...profileList]

    if (_list[index].name.length === 0) {
      _list[index].nameErrorMessage = ERROR_MESSAGES.NAME_EMPTY
    } else {
      if (_list[index].name.length < MIN_NAME_CHARACTERS) {
        _list[index].nameErrorMessage = ERROR_MESSAGES.NAME_CHARACTERS
      } else {
        _list[index].nameErrorMessage = ''
      }
    }

    setProfileList(_list)
  }

  const validateProfileCourse = (index) => {
    const _list = [...profileList]

    if (_list[index].course.length === 0) {
      _list[index].courseErrorMessage = ERROR_MESSAGES.COURSE_EMPTY
    } else {
      _list[index].courseErrorMessage = ''
    }

    setProfileList(_list)
  }

  const hasProfileErrors = () => {
    validateProfileName(profileList.length - 1)
    validateProfileCourse(profileList.length - 1)
    return !!(
      profileList[profileList.length - 1].nameErrorMessage !== '' ||
      profileList[profileList.length - 1].courseErrorMessage !== ''
    )
  }

  const isCurrentPlayer = (index) => {
    const currentPlayerData = getFamilyUserData()
    return currentPlayerData.guid === profileList[index].playerGuid
  }

  const handleEditProfile = (index) => {
    const _list = [...profileList]
    _list[index].isSaved = false
    setProfileList(_list)
    setIsEditMode(true)
    setIsAdding(false)
    setShowAddButton(false)
  }

  const handleDeleteProfile = async (index) => {
    const profile = profileList[index]
    setIsCurrentPlayerDeleted(isCurrentPlayer(index))
    const removeResponse = await removePlayer(
      profile.courseGuid,
      profile.playerGuid
    )
    // ocLog('ProfilesForm deleteProfile removeResponse', removeResponse)

    if (removeResponse?.status === 'fail') return
    // TODO: mostrar error en pantalla

    // Al cambiar los cursos de la cuenta principal, se elimina de los almacenados
    const token = getToken(true)
    removeStoredCourses(token)

    Analysis.sendEvent(Analysis.EVENT.REMOVE_PLAYER, {
      current_total_players: profileList.length - 1
    })

    const _list = [...profileList]
    if (profileList.length > 1) {
      _list.splice(index, 1)
      setProfileList(_list)
      setShowAddButton(true)
    } else {
      setProfileList([EMPTY_PROFILE])
      setShowAddButton(false)
    }

    setIsEditMode(false)
    setIsLastSaved(false)
    setFormErrorMessage('')
  }

  const addEmptyProfile = () => {
    // if (!hasProfileErrors()) {
    //   setProfileList([...profileList, EMPTY_PROFILE])
    //   setShowAddButton(false)
    //   setIsEditMode(true)
    //   setIsAdding(true)
    // }

    if (profileList.length === MAX_PROFILES) {
      setFormErrorMessage(ERROR_MESSAGES.MAXED_PROFILES)
    } else {
      if (!hasProfileErrors()) {
        setProfileList([...profileList, EMPTY_PROFILE])
        setShowAddButton(false)
        setIsEditMode(true)
        setIsAdding(true)
      }
    }
  }

  // Create new player
  const handleSaveNewProfile = async () => {
    setIsWorking(true)
    clearTimeout(savingTimeout)

    savingTimeout = setTimeout(async () => {
      if (!hasProfileErrors() && !isLastSaved) {
        const currentProfile = profileList[profileList.length - 1]
        let course = userCourses[parseInt(currentProfile.course.value)]

        // Creates a course for the user if it doesn't exist
        if (!course) {
          const selectedCourse =
            licenseCourseOptions[parseInt(currentProfile.course.value)]

          const courseSettings = {
            name: selectedCourse.label,
            education_year_guid: selectedCourse.education_year.guid,
            education_discipline_guid: selectedCourse.discipline.guid,
            programs: [selectedCourse.programGuid]
          }

          course = await createCourse(courseSettings)
          setUserCourses([...userCourses, course])
        }

        // Create user
        const whoAmI = await getWhoAmI(true)
        const userSettings = {
          name: currentProfile.name,
          role: 'R01',
          username: whoAmI.guid + '.' + Date.now()
        }
        const userResponse = await createPlayer(userSettings)
        // ocLog('userResponse', userResponse)

        // Link user to selected course
        // const linkCourseResponse = await linkCourseWithPlayer(
        await linkCourseWithPlayer(course.guid, userResponse.guid)
        // TODO: show error/confirmation
        // ocLog('linkCourseResponse', linkCourseResponse)

        // Al cambiar los cursos de la cuenta principal, se elimina de los almacenados
        const token = getToken(true)
        removeStoredCourses(token)

        Analysis.sendEvent(Analysis.EVENT.ADD_PLAYER, {
          current_total_players: profileList.length
        })

        // Set player list states
        profileList[profileList.length - 1].isSaved = true
        profileList[profileList.length - 1].playerGuid = userResponse.guid
        profileList[profileList.length - 1].courseGuid = course.guid
        setShowAddButton(true)

        if (profileList.length === MAX_PROFILES) {
          setIsLastSaved(true)
          setIsEditMode(true)
        }

        setIsWorking(false)
        setIsEditMode(false)
        setIsAdding(false)
      }
    }, 250)
  }

  // Update player info - currently just name
  const handleUpdateProfile = async (index) => {
    const profile = profileList[index]

    if (!hasProfileErrors()) {
      const updateNameResponse = await updatePlayerName(
        profile.playerGuid,
        profile.name
      )
      // ocLog('ProfilesForm updateNameResponse', updateNameResponse)

      if (updateNameResponse?.status === 'fail') return

      // Al cambiar los cursos de la cuenta principal, se elimina de los almacenados
      const token = getToken(true)
      removeStoredCourses(token)

      Analysis.sendEvent(Analysis.EVENT.EDIT_PLAYER)

      // TODO: show error/confirmation
      profileList[index].isSaved = true
      setShowAddButton(true)
      setIsEditMode(false)
    }

    // update localstorage if we update current player info
    if (isCurrentPlayer(index)) {
      const _updatedFamilyUserData = getFamilyUserData()
      _updatedFamilyUserData.name = profile.name
      setFamilyUserData(_updatedFamilyUserData)
    }
  }

  // Input controls
  const onChangeName = (e, index) => {
    const { id, value } = e.target
    const _list = [...profileList]
    _list[index][id] = value
    setProfileList(_list)
    validateProfileName(index)
  }

  const onChangeCourse = (value, index, id) => {
    // ocLog('value', value)
    const _list = [...profileList]
    _list[index][id] = value
    setProfileList(_list)
    validateProfileCourse(index)
  }

  // Get available programs for license
  const getLicenseProgramsData = async () => {
    getLicensePrograms()
      .then((response) => {
        setLicensePrograms(response?.programs)
      })
      .catch((error) => {
        console.error('BBF editPlayers error', error)
      })
  }

  // Get available courses (ed year+level) for license
  const getCourseOptionsData = async () => {
    const courses = []
    for (const p in licensePrograms) {
      const programData = licensePrograms[p]
      const courseName =
        programData.education_year.name + ' ' + programData.education_level.name

      courses.push({
        value: p,
        label: courseName,
        education_level: programData.education_level,
        education_year: programData.education_year,
        discipline: programData.discipline,
        programGuid: programData.program.guid
      })
    }
    setLicenseCourseOptions(courses)
  }

  // Get available players
  const getInitialProfileList = async () => {
    getPlayersList()
      .then((response) => {
        setInitialProfileList(response)
        setIsProfilesDataLoaded(true)
      })
      .catch((error) => {
        console.error('BBF editPlayers error', error)
      })
  }

  useEffect(() => {
    getLicenseProgramsData()
    getInitialProfileList()
  }, [isProfilesDataLoaded])

  useEffect(() => {
    getCourseOptionsData()
  }, [licensePrograms])

  // useEffect(() => {
  //   // ocLog('licenseCourses', licenseCourseOptions)
  // }, [licenseCourseOptions])

  // Set the data for the players from the API
  useEffect(() => {
    // ocLog('initialProfileList', initialProfileList)
    setProfileList(initialProfileList)
  }, [initialProfileList])

  useEffect(() => {
    // ocLog('profileList', profileList)
  }, [profileList])

  return {
    licenseCourseOptions,
    initialProfileList,
    showAddButton,
    isEditMode,
    isAdding,
    isDisabledButton,
    profileList,
    isWorking,
    isCurrentPlayerDeleted,
    formErrorMessage,
    handleEditProfile,
    handleDeleteProfile,
    addEmptyProfile,
    handleSaveNewProfile,
    handleUpdateProfile,
    onChangeName,
    onChangeCourse
  }
}
