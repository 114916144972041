import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import translate from '../../../../../i18n/translate'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import {
  getSoundAssistance,
  getEffectsUserConfig
} from '../../../../../services/settingsService'
import { Analysis } from '../../../../../services/analysisService'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
// import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import StreakCard from './components/StreakCard/StreakCard'
import StreakProgress from './components/StreakProgress/StreakProgress'
import './RewardDailyGoal.scss'
import PopupWrapped from '../../PopupWrapped'
import { getStreakAllGamification } from '../../../../../assets/data/api'
import { setStreakDay } from '../../../../../store/RewardsSlice/RewardsSlice'
import { useGamificationActivityContext } from '../../../../../context/GamificationActivityContext/useGamificationActivityContext'
import { store } from '../../../../../store/configureStore'

const RewardDailyGoal = ({ handleCheck }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const [streakDayCurrent, setStreakDayCurrent] = useState()
  const [streakInfo, setStreakInfo] = useState()
  const [coinsDailyGoalCurrent, setCoinsDailyGoalCurrent] = useState()
  const dispatch = useDispatch()
  const { setDailyGoalInProgress } = useGamificationActivityContext()

  const isFlagEnabledGamification =
    store.getState().configuration.isFlagEnabledGamification

  const rewards = useSelector((s) => ({
    coinsDailyGoal: s.rewards.coinsDailyGoal,
    coinsAccumulated: s.rewards.coinsAccumulated,
    coinsStreak: s.rewards.coinsStreak,
    streakDay: s.rewards.streakDay
  }))

  useEffect(() => {
    if (isFlagEnabledGamification) {
      setDailyGoalInProgress(false)
      getStreak()
    } else {
      setStreakDayCurrent(rewards.streakDay)
      setCoinsDailyGoalCurrent(rewards.coinsDailyGoal)
    }

    setTimeout(() => {
      Analysis.sendEvent(Analysis.EVENT.COINS_EARNED, {
        coins_daily_goal_earned: coinsDailyGoalCurrent,
        coins_accumulated: rewards.coinsAccumulated
      })
    }, 800)

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Daily Goal Popup Viewed']
    )

    getSoundAssistance() &&
      getEffectsUserConfig() === 1 &&
      playSoundEffect('rewardDailyGoal_' + getCurrentLangString())
  }, [])

  const getStreak = async () => {
    const dataStreak = await getStreakAllGamification()
    console.log('Daily Goal received: dataStreak --- ', dataStreak)
    // Dias seguidos con daily goal
    // store.dispatch(setStreakDay(dataStreak.currentStreak))

    if (dataStreak && dataStreak.currentStreak === 0) {
      handleCheck()
      return
    }
    if (dataStreak) {
      dispatch(setStreakDay(dataStreak.currentStreak))
      setCoinsDailyGoalCurrent(dataStreak.mainRule?.rewardAmount)
      setStreakDayCurrent(dataStreak.currentStreak)
      setStreakInfo(dataStreak.streakInfo)
    }
  }

  return (
    <>
      <PopupWrapped>
        {((isFlagEnabledGamification && streakDayCurrent && streakInfo) ||
          !isFlagEnabledGamification) && (
          <>
            <div className="reward-popup__backdrop" onClick={handleCheck}></div>
            <div className="reward-popup reward-daily-goal">
              {renderSoundFXController()}
              <div className="reward-popup__top"></div>
              {/* <div className="reward-popup__close-icon" onClick={handleCheck}>
              <CloseIcon />
            </div> */}
              <div
                className="reward-popup__title"
                dangerouslySetInnerHTML={{
                  __html: translate('reward_daily_goal_text_1')
                }}
              ></div>
              <div className="reward-popup__subtitle">
                {translate('reward_daily_goal_streak_text')}
              </div>
              <div className="reward-daily-goal__streak-cards">
                <StreakCard
                  streakDay={streakDayCurrent}
                  step={1}
                  streakInfo={streakInfo}
                  isFlagEnabledGamification={isFlagEnabledGamification}
                />
                <StreakCard
                  streakDay={streakDayCurrent}
                  step={2}
                  streakInfo={streakInfo}
                  isFlagEnabledGamification={isFlagEnabledGamification}
                />
                <StreakCard
                  streakDay={streakDayCurrent}
                  step={3}
                  streakInfo={streakInfo}
                  isFlagEnabledGamification={isFlagEnabledGamification}
                />
                <StreakCard
                  streakDay={streakDayCurrent}
                  step={4}
                  streakInfo={streakInfo}
                  isFlagEnabledGamification={isFlagEnabledGamification}
                />
                <StreakCard
                  streakDay={streakDayCurrent}
                  step={5}
                  streakInfo={streakInfo}
                  isFlagEnabledGamification={isFlagEnabledGamification}
                />
              </div>

              <div className="reward-daily-goal__streak-progress">
                <StreakProgress
                  currentStreak={
                    isFlagEnabledGamification
                      ? streakDayCurrent
                      : streakDayCurrent + 1
                  }
                />
              </div>

              <div className="reward-daily-goal__actions">
                <div
                  className="reward-daily-goal__button"
                  onClick={handleCheck}
                >
                  {translate('reward_daily_goal__button')}
                </div>
              </div>
              <div className="reward-popup__bottom"></div>
            </div>
          </>
        )}
      </PopupWrapped>
    </>
  )
}
export default RewardDailyGoal
