import React from 'react'
import './LessonProgressBar.scss'

const LessonProgressBar = ({ progress, text, iconStart, iconEnd }) => {
  return (
    <div className="lesson-progress-bar">
      {iconStart && (
        <img className="lesson-progress-bar_icon-start" src={iconStart} />
      )}
      {iconEnd && (
        <img className="lesson-progress-bar_icon-end" src={iconEnd} />
      )}
      <div className="lesson-progress-bar_back" style={{ width: '100%' }}>
        <div
          className="lesson-progress-bar_fill"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <span className="lesson-progress-bar_text">{text}</span>
    </div>
  )
}

export default LessonProgressBar
