import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firstUnlockedLessonPending: false,
  firstBrokenBateryPending: false,
  firstUnlockedUnitPending: false,
  firstBateryCompletePending: false,
  rewardBatteryUnlockPending: false,
  firstBateryChargedPending: false,
  levelUpPending: false
}

const popupManagerSlice = createSlice({
  name: 'popupManager',
  initialState,
  reducers: {
    setFirstUnlockedLessonPending: (state, action) => {
      state.firstUnlockedLessonPending = action.payload
    },
    setFirstBrokenBateryPending: (state, action) => {
      state.firstBrokenBateryPending = action.payload
    },
    setFirstUnlockedUnitPending: (state, action) => {
      state.firstUnlockedUnitPending = action.payload
    },
    setFirstBateryCompletePending: (state, action) => {
      state.firstBateryCompletePending = action.payload
    },
    setRewardBatteryUnlockPending: (state, action) => {
      state.rewardBatteryUnlockPending = action.payload
    },
    setFirstBateryChargedPending: (state, action) => {
      state.firstBateryChargedPending = action.payload
    },
    setLevelUpPending: (state, action) => {
      state.levelUpPending = action.payload
    }
  }
})

export const {
  setFirstUnlockedLessonPending,
  setFirstBrokenBateryPending,
  setFirstUnlockedUnitPending,
  setFirstBateryCompletePending,
  setRewardBatteryUnlockPending,
  setFirstBateryChargedPending,
  setLevelUpPending
} = popupManagerSlice.actions

export default popupManagerSlice.reducer
