const SMARTLOOK = {
  identifyUser: (whoAmI) => {
    if (window.smartlook && whoAmI?.guid)
      window.smartlook('identify', whoAmI.guid, {
        name: whoAmI.name,
        lastname: whoAmI.lastname,
        email: whoAmI.email,
        username: whoAmI.username,
        isDemo: Boolean(whoAmI.is_demo)
      })
  }
}

export { SMARTLOOK }
