import React, { useState, useEffect } from 'react'
import Title_battery from '../../assets/img/title_battery.svg'
import logoSimple from '../../assets/img/logo.svg'
import translate from '../../i18n/translate'
import { Analysis } from '../../services/analysisService'
import PracticeButton from '../AppPracticePage/modules/atoms/PracticeButton/PracticeButton'
import { isIosDevice, isAndroidDevice } from '../../util/devices'
import {
  IOS_STORE_URL,
  ANDROID_INTENT_WITHOUT_PARAMS,
  ANDROID_INTENT_WITH_PARAMS
} from '../../config/constants/appSchemesAndStoreUrls'
import { getToken } from '../../services/util'
import AccessHelper from '../../services/util/accessHelper'
import { getCurrentLangString } from '../../i18n/initI18n'

const TitleScreenPage = () => {
  const [animationType, setAnimationType] = useState('bounceInDown')
  const [isLoading, setIsLoading] = useState(false)

  const [isIPhone, setIsIPhone] = useState(false)
  const [isAndroid, setIsAndroid] = useState(false)
  const [ssoSource, setSsoSource] = useState('blueberrySso')

  useEffect(() => {
    const isiPhone = isIosDevice()
    const isAndroid = isAndroidDevice()

    setIsIPhone(isiPhone)
    setIsAndroid(isAndroid)

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Start,
      Analysis.SEGMENT_EVENTS['Start Page']
    )

    setTimeout(() => setAnimationType('pulse'), 2000)

    const search = window.location.search
    const [, params] = search.split('?')
    if (params) {
      const urlParams = new URLSearchParams(params)
      const ssoSourceParam = urlParams.get('ssoSource')
      setSsoSource(ssoSourceParam)
    }
  }, [])

  const goToMap = () => {
    Analysis.sendEvent(Analysis.EVENT.GO_TO_MAP_FROM_CUSTOM_SSO)

    setIsLoading(!isLoading)
    window.location.href = '/game'
  }

  const openApp = () => {
    const accessInformation = AccessHelper.getAccessInformation()

    if (isIPhone) {
      // This will redirect to App store
      let language = getCurrentLangString()
      if (language === 'es' || language === 'pt') {
        language = '/' + language
      } else language = ''

      window.location = IOS_STORE_URL.replace('[LANGUAGE]', language)
    } else if (isAndroid) {
      if (
        accessInformation &&
        accessInformation.accessType === AccessHelper.ACCESS_TYPE_BB_SSO
      ) {
        const intent = ANDROID_INTENT_WITH_PARAMS.replace('[SOURCE]', ssoSource)
          .replace('[TOKEN]', getToken())
          .replace('[HOST]', window.location.hostname)

        window.location = intent
      } else {
        window.location = ANDROID_INTENT_WITHOUT_PARAMS
      }
    }
  }

  return (
    <div className="title-screen-page">
      <div className="title-screen-page-container">
        <div className="title-screen-page-logo-container">
          <img
            className="title-screen-pa__bb-logo"
            src={logoSimple}
            alt="Blueberry Math"
          />
        </div>
        <div className="fade-in">
          <div className={`title-screen-page__animation ${animationType}`}>
            <div className="title-screen-page__button-wrapper">
              <div className="title-screen-page__battery">
                <img
                  className="title-screen-page__battery-img"
                  src={Title_battery}
                />
              </div>
            </div>
          </div>
          <div className={'title-screen-page__actions'}>
            <PracticeButton
              text={translate(
                !isIPhone && !isAndroid
                  ? 'title_screen_button_start'
                  : 'title_screen_button_web'
              )}
              action={goToMap}
              type={5}
            />

            {isIPhone || isAndroid ? (
              <PracticeButton
                text={translate('title_screen_button_app')}
                action={openApp}
                type={5}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TitleScreenPage
