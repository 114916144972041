import {
  getLemonadeOnboardingConfig,
  updateLemonadeOnboardingConfig,
  getOnboardingConfig,
  updateOnboardingConfig
} from '../services/settingsService'

export default function useLemonadeOnboarding() {
  const MAX_ONBOARDING = 3

  const formatDate = (date) =>
    `${date.getFullYear()}-${
      date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth()
    }-${date.getDate()}`

  // Devuelve true si se debe mostrar el Onboarding y actualiza el contador
  function checkLemonadeOnboarding(templateName) {
    let showLemonadeOnboarding = false
    const _onboardingConfig = getLemonadeOnboardingConfig()
    const currentDate = formatDate(new Date())

    // Si está contemplado el template en el onboarding
    if (
      _onboardingConfig !== undefined &&
      _onboardingConfig[templateName] !== null &&
      _onboardingConfig[templateName] !== undefined
    ) {
      // Pasamos al formato nuevo si hace falta
      const templateData = checkLemonadeOnboardingFormat(
        _onboardingConfig[templateName]
      )

      // La última actualización no es de hoy y ya se ha mostrado antes
      if (
        currentDate !== _onboardingConfig[templateName].updated_at ||
        templateData.count === 0
      ) {
        if (templateData.count < MAX_ONBOARDING) {
          // y no se ha mostrado el maximo de veces
          // se muestra
          showLemonadeOnboarding = true
          // sumamos una visualización
          templateData.count++
          // // Guardamos la fecha de esta actualización
          templateData.updated_at = currentDate
        }
      }

      // // Guardamos la info
      _onboardingConfig[templateName] = templateData
      updateLemonadeOnboardingConfig(_onboardingConfig)
    }

    return showLemonadeOnboarding
  }

  function parseTemplateName(templateName) {
    return (
      templateName
        // .replaceAll(' & ', '_')
        .replace(/ & /g, '_')
        // .replaceAll('-', '')
        .replace(/-/g, '')
        // .replaceAll(' ', '_')
        .replace(/ /g, '_')
        // .replaceAll('_–_', '_')
        .replace(/_–_/g, '_')
    )
  }

  function checkLemonadeOnboardingFormat(currentTemplateData) {
    if (Number.isInteger(currentTemplateData)) {
      const newTemplateData = {
        count: currentTemplateData,
        last_viewed_at: formatDate(new Date())
      }
      return newTemplateData
    } else {
      return currentTemplateData
    }
  }

  function getTemplateKey(templateName, questionData) {
    const parsedTemplateName = parseTemplateName(templateName)
    let params = ''

    // Añade parámetros extra si es necesario
    if (parsedTemplateName === 'Clock') params = questionData.ui_style.type
    if (parsedTemplateName === 'Counting')
      params =
        questionData.operation === 'subtract'
          ? 'substract'
          : questionData.operation
    if (parsedTemplateName === 'Multiple_choice_multiple_response')
      params = questionData.showCheckIcon ? '' : 'click'
    if (parsedTemplateName === 'Multiple_choice_standard')
      params = questionData.showCheckIcon ? '' : 'click'

    const templateKey = params
      ? parsedTemplateName + '_' + params
      : parsedTemplateName

    return templateKey
  }

  const lemonadeEmptyOnboardingTemplateData = {
    count: 0,
    updated_at: formatDate(new Date())
  }

  const lemonadeEmptyOnboarding = {
    Cloze_math: lemonadeEmptyOnboardingTemplateData,
    Cloze_with_drop_down: lemonadeEmptyOnboardingTemplateData,
    Cloze_with_drag_drop: lemonadeEmptyOnboardingTemplateData,
    Cloze_with_text: lemonadeEmptyOnboardingTemplateData,
    Multiple_choice_standard: lemonadeEmptyOnboardingTemplateData,
    Multiple_choice_standard_click: lemonadeEmptyOnboardingTemplateData,
    Multiple_choice_multiple_response: lemonadeEmptyOnboardingTemplateData,
    Multiple_choice_multiple_response_click:
      lemonadeEmptyOnboardingTemplateData,
    Choice_matrix_inline: lemonadeEmptyOnboardingTemplateData,
    Match_list: lemonadeEmptyOnboardingTemplateData,
    Counting_count: lemonadeEmptyOnboardingTemplateData,
    Counting_add: lemonadeEmptyOnboardingTemplateData,
    Counting_substract: lemonadeEmptyOnboardingTemplateData,
    Clock_analog: lemonadeEmptyOnboardingTemplateData,
    Clock_digital: lemonadeEmptyOnboardingTemplateData,
    Order_list: lemonadeEmptyOnboardingTemplateData,
    Pathway: lemonadeEmptyOnboardingTemplateData,
    Number_line: lemonadeEmptyOnboardingTemplateData,
    Line_chart: lemonadeEmptyOnboardingTemplateData,
    Bar_chart: lemonadeEmptyOnboardingTemplateData,
    Pictograph: lemonadeEmptyOnboardingTemplateData,
    Pie_chart: lemonadeEmptyOnboardingTemplateData
  }

  function resetLemonadeOnboarding() {
    // ocLog('Resetting Lemonade Onboarding')
    const _onboardingConfig = getOnboardingConfig()
    _onboardingConfig.lemonade = lemonadeEmptyOnboarding
    updateOnboardingConfig(_onboardingConfig)
  }

  return {
    checkLemonadeOnboarding,
    resetLemonadeOnboarding,
    parseTemplateName,
    getTemplateKey,
    lemonadeEmptyOnboarding
  }
}
