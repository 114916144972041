import React from 'react'
import cn from '@aqrojo/class-names'
import ReactDOM from 'react-dom'
import useModalSimple from './useModalSimple'
import { ReactComponent as IconClose } from '../../../assets/icons/icon_xclose.svg'

function ModalSimple({
  onCloseStart = () => {},
  onCloseComplete = () => {},
  startHandle = () => {},
  open = false,
  showModal = false,
  withCloseButton = false,
  modalTopIcon,
  title,
  message,
  buttonText,
  customClass
}) {
  const PORTAL_ID = '__blueberry-modal-container-simple'

  const { root, container, shadow } = useModalSimple(
    PORTAL_ID,
    showModal,
    startHandle
  )

  return ReactDOM.createPortal(
    showModal && (
      <div
        ref={root}
        className={'modal-simple' + (customClass ? ' ' + customClass : '')}
      >
        <div ref={shadow} className="modal__shadow-layer" />
        {/* Pantalla de intro a la practica del profesor */}
        <div className="modal__content-wrapper-init">
          <div
            ref={container}
            className={cn('modal__content', {
              show: open,
              hide: !open
            })}
          >
            <div className="modal__content-wrapper-icon">
              <img src={modalTopIcon} alt="" />
            </div>
            <div className="modal__content-wrapper-init__container-text">
              <div className="modal__content-wrapper-init__title">{title}</div>
              <div
                className="modal__content-wrapper-init__subtitle"
                dangerouslySetInnerHTML={{ __html: message }}
              ></div>
            </div>

            {onCloseComplete && buttonText && (
              <button
                className="modal__content-wrapper-init__button"
                onClick={onCloseComplete}
              >
                {buttonText}
              </button>
            )}
            {withCloseButton && (
              <button
                className="modal__close-button"
                onClick={() => {
                  onCloseStart()
                }}
              >
                <IconClose className="iconClose" />
              </button>
            )}
          </div>
        </div>
      </div>
    ),
    document.getElementById(PORTAL_ID)
  )
}

export default ModalSimple
