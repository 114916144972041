import { useEffect, useState } from 'react'
import { APP_ANALYSIS } from '../../../services/analysisServiceReactNative'
import { store } from '../../../../../store/configureStore'
import { changeDevMode } from '../../../../../store/Configuration/Configuration'
import DIFFICULT_ACTIVITY_COINS_REWARD from '../../../../../config/constants/difficultActivity'

export function useActivityHeader({
  currentExercise,
  previousSeedId,
  numberExercisesCorrect,
  numberExercisesIncorrect,
  practiceStartedDate,
  barCurrent,
  barMax,
  messageToReactNative,
  setShowAbortPracticePopup,
  setShowIssueReportPopup,
  isFromApp,
  difficultActivityAnswered,
  practiceBaseData,
  practiceProgressData,
  isFlagEnabledGamification
}) {
  const [lessonCurrentPieces, setLessonCurrentPieces] = useState(null)
  const [lessonTotalPieces, setLessonTotalPieces] = useState(null)
  const [practiceCoinsAccumulated, setPracticeCoinsAccumulated] = useState(
    isFlagEnabledGamification ? 0 : 0
  )

  useEffect(() => {
    const userPieces = practiceBaseData.lessonPiecesHave.reduce(
      (sum, curr) => sum + curr,
      0
    )
    const totalPieces = practiceBaseData.lessonPiecesTotal.reduce(
      (sum, curr) => sum + curr,
      0
    )

    setLessonCurrentPieces(userPieces)
    setLessonTotalPieces(totalPieces)

    window.addEventListener('user-earned-piece', handleEarnedPiece)

    return () => {
      window.removeEventListener('user-earned-piece', handleEarnedPiece)
    }
  }, [])

  useEffect(() => {
    if (difficultActivityAnswered === true && !isFlagEnabledGamification) {
      setPracticeCoinsAccumulated(
        practiceCoinsAccumulated + DIFFICULT_ACTIVITY_COINS_REWARD
      )
    } //  else if (difficultActivityAnswered === false) {}
  }, [difficultActivityAnswered])

  useEffect(() => {
    if (isFlagEnabledGamification) {
      setPracticeCoinsAccumulated(practiceProgressData.earnedCoins)
    } //  else if (difficultActivityAnswered === false) {}
  }, [practiceProgressData.earnedCoins])

  const handleEarnedPiece = (event) => {
    setLessonCurrentPieces((prev) => prev + 1)
  }

  const forcedCloseExecise = () => {
    if (barCurrent > 0) {
      setShowAbortPracticePopup(true)
    } else {
      APP_ANALYSIS.sendAnalysisEventToReactNative(
        isFromApp,
        APP_ANALYSIS.APP_ANALYSIS_EVENT['Practice Session Aborted'],
        {
          practice_session_id: currentExercise?.session_id,
          program_id: isFromApp
            ? 'from App'
            : store.getState()?.practice?.programId,
          unit_id: currentExercise?.unit_guid,
          lesson_id: currentExercise?.lesson_guid,
          current_seed_id: currentExercise?.question?.seed_guid,
          previous_seed_id: previousSeedId,
          number_activities_correct: numberExercisesCorrect,
          number_activities_incorrect: numberExercisesIncorrect,
          time_taken: new Date() - practiceStartedDate,
          current_session_points: barCurrent
        }
      )

      if (isFromApp) {
        messageToReactNative({ function: 'onPracticeAborted' })
      } else {
        window.dispatchEvent(new CustomEvent('exit-activities'))
      }
    }
  }

  const getBarPercentage = () => {
    if (!barCurrent || !barMax) return 0

    let percentage = (barCurrent / barMax) * 100

    if (percentage < 0) percentage = 0
    if (percentage > 100) percentage = 100

    return percentage + '%'
  }

  const openIssueReportPopup = () => {
    setShowIssueReportPopup(true)
  }

  const toogleDevMode = () => {
    if (store.getState().configuration.canUseDevMode) {
      store.dispatch(changeDevMode())
    }
  }

  return {
    practiceCoinsAccumulated,
    lessonCurrentPieces,
    lessonTotalPieces,
    forcedCloseExecise,
    getBarPercentage,
    openIssueReportPopup,
    toogleDevMode
  }
}
