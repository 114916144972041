import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import {
  isLargerThanMinimunSize,
  isDeviceSupported,
  getDeviceType
} from '../../util/devices'

const RulerPage = () => {
  const [width, setWidth] = useState(window.screen.width)
  const [widthBlocks, setWidthBlocks] = useState(
    Math.ceil(window.screen.width / 100)
  )

  const [height, setHeight] = useState(window.screen.height)
  const [heightBlocks, setHeightBlocks] = useState(
    Math.ceil(window.screen.height / 100)
  )

  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  useEffect(() => {
    window.addEventListener('resize', debounceGenerateBlocks)

    return () => {
      window.removeEventListener('resize', debounceGenerateBlocks)
    }
  }, [])

  const isInnerDifferent = () => {
    return width !== innerWidth || height !== innerHeight
  }

  const generateBlocks = () => {
    setWidth(window.screen.width)
    setWidthBlocks(Math.ceil(window.screen.width / 100))

    setHeight(window.screen.height)
    setHeightBlocks(Math.ceil(window.screen.height / 100))

    setInnerWidth(window.innerWidth)
    setInnerHeight(window.innerHeight)
  }

  const debounceGenerateBlocks = debounce(generateBlocks, 300)

  const toogleFullScreenMode = () => {
    if (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.msFullscreenEnabled
    ) {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else if (document.exitFullscreen) {
        document.exitFullscreen()
      }

      generateBlocks()
    }
  }

  return (
    <div
      id={'ruler-page'}
      className="ruler-page"
      onClick={toogleFullScreenMode}
    >
      <div className="ruler-page_size">
        <div>{width + ' x ' + height}</div>
        <div>
          <span className="title">{'Device:        '}</span>
          {getDeviceType()}
        </div>
        <div>
          <span className="title">{'Large enought?:        '}</span>
          {'' + isLargerThanMinimunSize()}
        </div>
        <div>
          <span className="title">{'Supported?:        '}</span>
          {'' + isDeviceSupported()}
        </div>

        <div>
          <span className="title">{'User Agent:        '}</span>
          {'' + navigator.userAgent.toLowerCase()}
        </div>
        {isInnerDifferent() && (
          <div className="ruler-page_inner-size">
            {'(inner: ' + innerWidth + ' x ' + innerHeight + ')'}
          </div>
        )}
      </div>

      <div className="ruler-page_width-wrapper">
        {[...Array(widthBlocks).keys()].map((element, index) => (
          <div key={index} className="ruler-page_width-block">
            {(element + 1) * 100}
          </div>
        ))}
      </div>

      <div className="ruler-page_height-wrapper">
        {[...Array(heightBlocks).keys()].map((element, index) => (
          <div key={index} className="ruler-page_height-block">
            {(element + 1) * 100}
          </div>
        ))}
      </div>
    </div>
  )
}

export default RulerPage
